import { Route, Routes } from 'react-router-dom'

import NotFound from 'components/layouts/NotFound/index'
import RouteValidator from './RouteValidator'
import { routerList } from './routerList'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { firstVisit, getAuthToken, isFirstAccess } from 'helpers/cookie.helpers'
import { setLoggedIn } from 'redux-thunk/redux/user/user.slice'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useSearchParams } from '../../node_modules/react-router-dom/index'
import useLocalStorage from 'hooks/useLocalStorage'

const CustomRoutes = () => {
  const { isLoggedIn } = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const { setStorage } = useLocalStorage();

  let referralCode = searchParams.get("referralCode");

  useEffect(() => {
    if (getAuthToken()) {
      dispatch(setLoggedIn({ isLoggedIn: true }))
    }
    firstVisit()
  }, [])

  useEffect(() => {
    if (isFirstAccess(pathname == ROUTE_PATHS.casino) && !isLoggedIn && !getAuthToken() && pathname == ROUTE_PATHS.casino) navigate(ROUTE_PATHS.home, { replace: true })

    const playerPlugin = document.getElementById('__onlybetsplugin');
    if (playerPlugin) {
      if (pathname == ROUTE_PATHS.sports) {
        playerPlugin.style.display = 'none';
      } else {
        playerPlugin.style.removeProperty('display')
      }
    }
  }, [pathname])

  useEffect(() => {
    if (referralCode) setStorage("referralCode", referralCode);
  }, []);

  return (
    <Routes>
      {routerList.map(({ path, key, ...props }) => {
        return (
          <Route
            path={path}
            key={key}
            exact
            element={<RouteValidator path={path} {...props} />}
          />
        )
      })}
      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  )
}

export default CustomRoutes
