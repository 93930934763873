import { ThemeProvider } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from 'redux-thunk/store'
import { create } from 'jss';
import { createGenerateClassName } from '@mui/styles';
import { StylesProvider, jssPreset } from '@mui/styles';

import { appRoot } from 'assets/domNodes/index'
import App from './App'
import './assets/css/index.css'
import reportWebVitals from './reportWebVitals'
import './translation/index'
import { theme } from 'theme/index'
import GetSiteInformationSettings from 'providers/GetSiteInformationSettings/index'
import PosRegistrationVideoFloat from 'components/ui-kit/posRegistrationVideoFloat/index'

const generateClassName = createGenerateClassName({
  productionPrefix: 'onl-bts-',
  disableGlobal: true,
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <GetSiteInformationSettings>
            <App />
            <PosRegistrationVideoFloat />
          </GetSiteInformationSettings>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  appRoot
)

export default store

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
