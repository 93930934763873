import { useEffect, useState } from "react"

const PlayerPluginProps = {
  isStreaming: false,
  reload: (/** @type { OnlybetsPluginOptions } */ options) => { },
  moveToElement: (/** @type { Element | string } */ selector) => { },
  returnToDefaultElement: () => { },
  reloadAfterDeposit: () => { },
  setUser: (/** @type { object } */ user) => { },
  isLive: () => { return window['OnlybetsPluginRef']?.isStreaming },
  on: (/**@type { PlayerPluginEvents }*/ event, cb = () => null) => { },
  emit: (/**@type { PlayerPluginEvents }*/ event, data) => { },
  interceptor: {
    "click:home:on": async () => true,
    "click:player": async () => true,
    "moveToElement": async () => true,
    "returnToDefaultElement": async () => true,
    "click:player:chat": async () => true,
    "click:player:close": async () => true,
    "demo:timeout:finished": async () => true,
    "click:deposit": async () => true,
  },
}
const PlayerPlugin = Object.assign(PlayerPluginProps, window['OnlybetsPluginRef'])

/** Ajudante para manipular o player de lives */
export const usePlayerPlugin = (deps = []) => {
  /** @type {[PlayerPluginProps, () => any]} */
  const [plugin, setPlugin] = useState(window['OnlybetsPluginRef'] ?? PlayerPlugin);

  useEffect(() => {
    if (window['OnlybetsPluginRef']) {
      window['OnlybetsPluginRef'].isLive = PlayerPlugin.isLive;

      setPlugin(window['OnlybetsPluginRef']);
    };
  }, deps);

  return () => window['OnlybetsPluginRef'] ?? plugin ?? PlayerPlugin;
};

/** 
 * @typedef {{
*  streamerId?: string,
*  casinoGameId?: number;
*  demoTimeout:? number;
*  isStreaming:? boolean;
*  randomLive:? boolean;
* }} OnlybetsPluginOptions
*/

/** 
 * @typedef { 'click:home:on' | 'click:player' | 'moveToElement' | 'returnToDefaultElement' | 'click:player:close' | 'click:player:chat' | 'demo:timeout:finished' | 'click:deposit' | 'click:already-deposited' } PlayerPluginEvents
*/