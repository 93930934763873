import { createSlice } from '@reduxjs/toolkit'

import { getSiteInformationSettingsThunk } from 'redux-thunk/thunk/site/site.thunk';

const defaultState = {
  settings: {},
  settingsLoading: true,
}

const siteSlice = createSlice({
  name: 'siteSlice',
  initialState: defaultState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteInformationSettingsThunk.fulfilled, (state, action) => {
        return {
          ...state,
          settings: action.payload,
          settingsLoading: false,
        }
      })
      .addCase(getSiteInformationSettingsThunk.rejected, (state, action) => {
        return {
          ...state,
          settingsLoading: false,
        }
      })
      .addCase(getSiteInformationSettingsThunk.pending, (state, action) => {
        return {
          ...state,
          settingsLoading: true,
        }
      })
  }
})

export const { handleUserNameChange, handleLanguageChange, handleBalanceChange } = siteSlice.actions

export default siteSlice.reducer
