import { io } from 'socket.io-client'

const createSocketConnection = (connectionUrl, authToken) => {
  const obj = {
    // withCredentials: true,
    // transport: ['polling', 'webSocket'],
    extraHeaders: {
      'access-token': authToken
    },
    auth: {
      'access-token': authToken
    }
    // ...(authToken
    //   ? {
    //       auth: {
    //         authorization: `Bearer ${authToken}`
    //       }
    //     }
    //   : {})
  }
  const ioConnection = io(connectionUrl,
    obj
  )

  return ioConnection
}

export default createSocketConnection
