import { createSlice } from '@reduxjs/toolkit'
import {
  fetchGameProvidersThunk,
  fetchProviderGamesThunk,
  fetchGameCategoryThunk,
  fetchGameSubCategoryThunk,
  fetchGameSubCategoryMenuThunk,
  fetchGamesThunk,
  initGameStartThunk,
  fetchSearchedGamesThunk,
  fetchBannerThunk,
  addTofavoriteGameThunk,
  deleteTofavoriteGameThunk,
  fetchFavoriteGamesThunk,
  fetchFavoriteGameByIdThunk
} from 'redux-thunk/thunk/casino/casino.thunk'

const defaultCasinoState = {
  gameSubCategoryList: null,
  gameSubCategoryTitleList: null,
  gameSubCategoryTitleListMenu: null,
  gameCategoryLoader: false,
  gameSubCategoryLoader: false,
  gameSubCategoryActiveTab: 0,
  gameSubCategoryActive: null,
  gameProviderList: null,
  gameStartInfo: null,
  gamesList: null,
  searchedGamesList: null,
  searchGamesLoader: false,
  isViewAllGame: false,
  bannerListData: null,
  bannerListDataLoader: false,
  subCategoryGamesList: [],
  subCategoryGamesHasMoreData: false,
  gameCategories: [],
  addTofavoriteGameData: null,
  deleteTofavoriteGameData: null,
  favoriteGamesList: [],
  indivisualFavoriteGamesData: null,
  favouriteGamesLoader: false,
  providerGamesLoader: false,
  providerGames: [],
  providerGamesHasMoreData: false,
  providerMeta: {},
  openSearchPopup: false,
  favouriteGamesHasMoreData: false,
  allFavouriteGamesLoader: false,
  isFavouriteGameDeleted: false,
  currentPath: ""

}

const casinoSlice = createSlice({
  name: 'casinoSlice',
  initialState: defaultCasinoState,
  reducers: {
    resetGameStartInfo: (state) => {
      return {
        ...state,
        gameStartInfo: null
      }
    },
    setGameSubCategoryActiveTab: (state, action) => {
      return {
        ...state,
        gameSubCategoryActiveTab: action.payload
      }
    },
    setGameSubCategoryActive: (state, action) => {
      return {
        ...state,
        gameSubCategoryActive: action.payload
      }
    },
    setIsViewAllGame: (state, action) => {
      return {
        ...state,
        isViewAllGame: action.payload
      }
    },
    setSubCategoryGamesList: (state, action) => {
      return {
        ...state,
        subCategoryGamesList: action.payload,
        subCategoryGamesHasMoreData: false
      }
    },
    setSearchedGamesList: (state, action) => {
      return {
        ...state,
        searchedGamesList: action.payload,
      }
    },
    setOpenSearchPopup: (state, action) => {
      return {
        ...state,
        openSearchPopup: action.payload,
      }
    },
    setFavoriteGamesList: (state, action) => {
      return {
        ...state,
        favoriteGamesList: action.payload,
      }
    },
    setIsFavouriteGameDeleted: (state, action) => {
      return {
        ...state,
        isFavouriteGameDeleted: action.payload,
      }
    },
    setGameSubCategoryList: (state, action) => {
      return {
        ...state,
        gameSubCategoryList: action.payload,
      }
    },
    setGameSubCategoryTitleList: (state, action) => {
      return {
        ...state,
        gameSubCategoryTitleList: action.payload,
      }
    },
    setGameSubCategoryTitleListMenu: (state, action) => {
      return {
        ...state,
        gameSubCategoryTitleListMenu: action.payload,
      }
    },
    setBannerListData: (state, action) => {
      return {
        ...state,
        bannerListData: action.payload,
      }
    },
    setCurrentPath: (state, action) => {
      return {
        ...state,
        currentPath: action.payload,
      }
    },
    resetProviderGamesList: (state) => {
      return {
        ...state,
        providerGamesLoader: false,
        providerGames: [],
        providerGamesHasMoreData: false,
        providerMeta: {}
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameCategoryThunk.fulfilled, (state, action) => {
        const gameCategories = action.payload?.rows

        return {
          ...state,
          gameCategories: gameCategories,
          gameCategoryLoader: false
        }
      })
      .addCase(fetchGameCategoryThunk.rejected, (state, action) => {
        return {
          ...state,
          gameCategoryLoader: false
        }
      })
      .addCase(fetchGameCategoryThunk.pending, (state, action) => {
        return {
          ...state,
          gameCategoryLoader: true
        }
      })
      .addCase(fetchGameSubCategoryThunk.fulfilled, (state, action) => {
        const subCategoryTitlerows = action.payload?.rows
          ?.filter(subCategoryGames => subCategoryGames?.CasinoGames?.length)
          .map(rowData => {
            const tempRowData = { ...rowData }
            delete tempRowData.CasinoGames
            return tempRowData
          })
          ?.sort((a, b) => a?.secondaryOrderId - b?.secondaryOrderId)

        const suCategoryTitle = {
          count: action?.payload?.count,
          rows: subCategoryTitlerows
        }
        const subCategoryAllData = action.payload?.rows
          ?.filter(subCategoryGames => subCategoryGames?.CasinoGames?.length)
        // .sort((a, b) => a?.gameSubCategoryId - b?.gameSubCategoryId)
        return {
          ...state,
          gameSubCategoryList: subCategoryAllData,
          gameSubCategoryTitleList: suCategoryTitle,
          gameSubCategoryLoader: false
        }
      })
      .addCase(fetchGameSubCategoryThunk.rejected, (state, action) => {
        return {
          ...state,
          gameSubCategoryLoader: false
        }
      })
      .addCase(fetchGameSubCategoryThunk.pending, (state, action) => {
        return {
          ...state,
          gameSubCategoryLoader: true
        }
      })
      .addCase(fetchGameSubCategoryMenuThunk.fulfilled, (state, action) => {
        const subCategoryTitlerows = action.payload?.rows
          ?.filter(subCategoryGames => subCategoryGames?.CasinoGames?.length)
          .map(rowData => {
            const tempRowData = { ...rowData }
            delete tempRowData.CasinoGames
            return tempRowData
          })
          ?.sort((a, b) => a?.secondaryOrderId - b?.secondaryOrderId)

        const suCategoryTitle = {
          count: action?.payload?.count,
          rows: subCategoryTitlerows
        }

        return {
          ...state,
          gameSubCategoryTitleListMenu: suCategoryTitle,
        }
      })
      .addCase(fetchGameSubCategoryMenuThunk.pending, (state, action) => {
        return {
          ...state,
          gameSubCategoryLoader: true
        }
      })
      .addCase(fetchGameProvidersThunk.fulfilled, (state, action) => {
        return {
          ...state,
          gameProviderList: action.payload
        }
      })
      .addCase(initGameStartThunk.fulfilled, (state, action) => {
        return {
          ...state,
          gameStartInfo: action.payload
        }
      })
      .addCase(fetchGamesThunk.fulfilled, (state, action) => {
        return {
          ...state,
          gamesList: action.payload?.casinoGames,
          subCategoryGamesList: [...state?.subCategoryGamesList, ...action?.payload?.casinoGames?.rows],
          subCategoryGamesHasMoreData: action?.payload?.casinoGames?.rows?.length > 0
        }
      })
      .addCase(fetchGamesThunk.rejected, (state, action) => {
        return {
          ...state,
          gamesList: [],
          subCategoryGamesHasMoreData: false
        }
      })
      .addCase(fetchGamesThunk.pending, (state, action) => {
        return {
          ...state,
          subCategoryGamesHasMoreData: true
        }
      })
      .addCase(fetchSearchedGamesThunk.fulfilled, (state, action) => {
        return {
          ...state,
          searchedGamesList: action.payload?.casinoGames,
          searchGamesLoader: false,
        }
      })
      .addCase(fetchSearchedGamesThunk.pending, (state, action) => {
        return {
          ...state,
          searchGamesLoader: true,
        }
      })
      .addCase(fetchSearchedGamesThunk.rejected, (state, action) => {
        return {
          ...state,
          searchedGamesList: action.payload?.casinoGames,
          searchGamesLoader: false
        }
      })
      .addCase(fetchBannerThunk.fulfilled, (state, action) => {
        return {
          ...state,
          bannerListData: action.payload,
          bannerListDataLoader: false,
        }
      })
      .addCase(fetchBannerThunk.pending, (state, action) => {
        return {
          ...state,
          bannerListData: action.payload,
          bannerListDataLoader: true,
        }
      })
      .addCase(fetchBannerThunk.rejected, (state, action) => {
        return {
          ...state,
          bannerListData: [],
          bannerListDataLoader: false,
        }
      })
      .addCase(addTofavoriteGameThunk.fulfilled, (state, action) => {
        return {
          ...state,
          addTofavoriteGameData: action.payload,
          favouriteGamesLoader: false
        }
      })
      .addCase(addTofavoriteGameThunk.pending, (state, action) => {
        return {
          ...state,
          favouriteGamesLoader: true
        }
      })
      .addCase(addTofavoriteGameThunk.rejected, (state, action) => {
        return {
          ...state,
          addTofavoriteGameData: action.payload,
          favouriteGamesLoader: false
        }
      })
      .addCase(deleteTofavoriteGameThunk.fulfilled, (state, action) => {
        return {
          ...state,
          deleteTofavoriteGameData: action.payload,
          favouriteGamesLoader: false
        }
      })
      .addCase(deleteTofavoriteGameThunk.pending, (state, action) => {
        return {
          ...state,
          favouriteGamesLoader: true
        }
      })
      .addCase(deleteTofavoriteGameThunk.rejected, (state, action) => {
        return {
          ...state,
          deleteTofavoriteGameData: action.payload,
          favouriteGamesLoader: false
        }
      })
      .addCase(fetchFavoriteGamesThunk.fulfilled, (state, action) => {
        return {
          ...state,
          favoriteGamesList: [...state.favoriteGamesList, ...action.payload?.favoriteGames?.rows],
          allFavouriteGamesLoader: false,
          favouriteGamesHasMoreData: action.payload?.favoriteGames?.rows?.length > 0
        }
      })
      .addCase(fetchFavoriteGamesThunk.pending, (state, action) => {
        return {
          ...state,
          allFavouriteGamesLoader: true,
          favouriteGamesHasMoreData: false
        }
      })
      .addCase(fetchFavoriteGamesThunk.rejected, (state, action) => {
        return {
          ...state,
          favoriteGamesList: action.payload,
          allFavouriteGamesLoader: false,
          favouriteGamesHasMoreData: false
        }
      })
      .addCase(fetchFavoriteGameByIdThunk.fulfilled, (state, action) => {
        return {
          ...state,
          indivisualFavoriteGamesData: action.payload?.gameDetails,
          favouriteGamesLoader: false
        }
      })
      .addCase(fetchFavoriteGameByIdThunk.pending, (state, action) => {
        return {
          ...state,
          favouriteGamesLoader: true
        }
      })
      .addCase(fetchFavoriteGameByIdThunk.rejected, (state, action) => {
        return {
          ...state,
          indivisualFavoriteGamesData: action.payload,
          favouriteGamesLoader: false
        }
      })
      .addCase(fetchProviderGamesThunk.fulfilled, (state, action) => {
        return {
          ...state,
          providerGames: [...state.providerGames, ...action?.payload?.casinoGames?.rows],
          providerMeta: { count: action?.payload?.casinoGames?.count },
          providerGamesHasMoreData: action?.payload?.casinoGames?.rows?.length > 0,
          providerGamesLoader: false
        }
      })
      .addCase(fetchProviderGamesThunk.pending, (state, action) => {
        return {
          ...state,
          providerGamesLoader: true
        }
      })
      .addCase(fetchProviderGamesThunk.rejected, (state, action) => {
        return {
          ...state,
          providerGamesLoader: false
        }
      })
  }
})

export const {
  resetGameStartInfo,
  setGameSubCategoryActiveTab,
  setGameSubCategoryActive,
  setIsViewAllGame,
  setSubCategoryGamesList,
  setSearchedGamesList,
  resetProviderGamesList,
  setOpenSearchPopup,
  setFavoriteGamesList,
  setIsFavouriteGameDeleted,
  setGameSubCategoryList,
  setGameSubCategoryTitleList,
  setBannerListData,
  setCurrentPath
} = casinoSlice.actions

export default casinoSlice.reducer

export const useCasinoState = (useSelector) => useSelector((state) => state.casino)
