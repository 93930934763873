import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBetHistoryDetailsService, fetchBonusClaimService, fetchBonusDetailsService, fetchSportsTransactionReportService, fetchTransactionReportsDetailsService } from 'network/services/transaction.service'


export const fetchTransactionReportsDetailsThunk = createAsyncThunk('transactions/details', async (payload, thunkApi) => {
  try {
    const res = await fetchTransactionReportsDetailsService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const fetchBetHistoryDetailsThunk = createAsyncThunk('betslip/details', async (payload, thunkApi) => {
  try {
    const res = await fetchBetHistoryDetailsService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const fetchSportsTransactionReportThunk = createAsyncThunk('sports/transaction-report', async (payload, thunkApi) => {
  try {
    const res = await fetchSportsTransactionReportService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const fetchBonusDetailsThunk = createAsyncThunk('bonus/details', async (payload, thunkApi) => {
  try {
    const res = await fetchBonusDetailsService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const fetchBonusClaimThunk = createAsyncThunk('bonus/claim', async (payload, thunkApi) => {
  try {
    const res = await fetchBonusClaimService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})


