import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchInfluencerListService, fetchPlayingNowListService } from 'network/services/influencer.services';

/**
 * Fetch affiliate list
 * @typedef { {limit?: number, pageNo?: number, isLive?: boolean } } AffiliateListPayload
 * @type { (params: {payload?: AffiliateListPayload}) => any }
 */
export const fetchInfluencerListServiceThunk = createAsyncThunk('affiliate/all', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchInfluencerListService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].name)
  }
})
export const fetchInfluencerListSocketServiceThunk = createAsyncThunk('affiliate/all/socket', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchInfluencerListService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].name)
  }
})

/**
 * Fetch affiliate list
 * @typedef { {limit?: number, pageNo?: number, isLive?: boolean } } PlayingNowListPayload
 * @type { (params: {payload?: PlayingNowListPayload}) => any }
 */
export const fetchPlayingNowListServiceThunk = createAsyncThunk('affiliate/playing-now', async ({ payload, navigate }, thunkApi) => {
  try {
    const res = await fetchPlayingNowListService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].name)
  }
})
