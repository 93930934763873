import React, { useMemo, useRef, useState } from "react";
import useStyles from "./GameCard.styles";
import { InfoIcon } from "components/ui-kit/icons/iconComponents/InfoIcon";
import { PlayButtonIcon } from "components/ui-kit/icons/iconComponents/PlayButtonIcon";
import {
  Grid,
  Link,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  resetGameStartInfo,
  setIsFavouriteGameDeleted,
  setOpenSearchPopup,
  useCasinoState,
} from "redux-thunk/redux/casino/casino.slice";
import ICards from "./components/ICards";
import { useTranslation } from "react-i18next";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SkeletonImage from "../../../assets/images/onlybetc-default.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addTofavoriteGameThunk,
  deleteTofavoriteGameThunk,
} from "redux-thunk/thunk/casino/casino.thunk";
import NotSigninDialog from "components/ui-kit/Dialog/NotSigninDialog/index";
import InSufficiantBalDialog from "components/ui-kit/Dialog/InSufficiantBalDialog/index";

import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

export const GameCard = ({ game, gameSubCategoryName, streamer = {}, className, showPlayButton = true, winnerAmountContent }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showICard, setShowIcard] = useState(null);
  const { userInformationData } = useSelector((state) => state.user);
  const [isFavouriteGame, setIsFavouriteGame] = useState(game?.isFavorite);
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useSelector((state) => state.user);
  const [thumbLoading, setThumbLoading] = useState(true);

  const NotSigninDialogRef = useRef(null);
  const InSufficiantBalDialogRef = useRef(null);

  const addTofavoriteGame = () => {
    NotSigninDialogRef?.current?.validate(() => {
      setIsLoading(true);
      const payload = {
        casinoGameId: Number(game?.parentId ?? game?.casinoGameId),
        userId: userInformationData?.userId,
      };
      dispatch(addTofavoriteGameThunk(payload)).then(() => {
        setIsFavouriteGame(true);
        setIsLoading(false);
      });
    })
  };

  const deleteTofavoriteGame = () => {
    NotSigninDialogRef?.current?.validate(() => {
      setIsLoading(true);
      const payload = {
        casinoGameId: Number(game?.parentId ?? game?.casinoGameId),
        id: userInformationData?.userId,
      };
      dispatch(deleteTofavoriteGameThunk(payload)).then((result) => {
        setIsFavouriteGame(false);
        setIsLoading(false);
        dispatch(
          setIsFavouriteGameDeleted({
            isFavouriteGameDeleted: result?.payload?.success,
            casinoGameId: game?.parentId ?? game?.casinoGameId,
          })
        );
      });
    })
  };

  const handleGameClick = (game, streamer) => {
    NotSigninDialogRef?.current?.validate(() => {
      InSufficiantBalDialogRef?.current?.validate("game", () => {
        dispatch(resetGameStartInfo(null));
        navigate(
          `/casino/${game?.parentId ?? game?.casinoGameId}`,
          {
            state: { gameSubCategoryId: game?.gameSubCategoryId },
          }
        );
      }, () => {
        dispatch(resetGameStartInfo(null));
      });
    })
  };

  const handleTryForFreeClick = (game) => {
    navigate(`/casino/${game?.parentId ?? game?.casinoGameId}`, {
      state: { gameSubCategoryId: game?.gameSubCategoryId, demo: game?.demo },
    });
  };
  const favouriteGameHandler = () => {
    isFavouriteGame ? deleteTofavoriteGame() : addTofavoriteGame();
  };

  const renderGameSubCategory = useMemo(() => {
    if (game?.GameSubCategory?.name) {
      const name = JSON.parse(game?.GameSubCategory?.name)?.[language?.toUpperCase()]
        ? JSON.parse(game?.GameSubCategory?.name)?.[language?.toUpperCase()]
        : JSON.parse(game?.GameSubCategory?.name)?.["EN"?.toUpperCase()];
      return name;
    }
    return gameSubCategoryName ?? "-";
  }, [gameSubCategoryName, game?.GameSubCategory?.name, language]);

  return (
    <>
      <Grid className={`${classes.gameCardVariables} ${classes.themeCardWrap}${className ? ` ${className}` : ''}`}>
        {winnerAmountContent}
        <Grid className={classes.themeCard}>
          <Grid className={classes.casinoImgWrap}>
            <img
              src={game?.thumbnailUrl || game?.gameImage || SkeletonImage}
              alt="Casino"
              lazy="loaded"
              onLoad={() => setThumbLoading(false)}
              style={thumbLoading ? { opacity: 0 } : {}}
            />
            {showPlayButton ? <>
              <Grid
                className={classes.cardOverlayContent}
                onClick={() => handleGameClick(game, streamer)}
              >
                <PlayButtonIcon />
              </Grid>
              {game?.demo && (
                <Typography
                  className={classes.tryForFree}
                  onClick={() => handleTryForFreeClick(game)}
                >
                  {t("tryForFree")}
                </Typography>
              )}
            </> : null}

            <Grid className={classes.favIcon}>
              <IconButton
                aria-label="favourite"
                className={classes.addedInFav}
                onClick={favouriteGameHandler}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : isFavouriteGame ? (
                  <FavoriteIcon style={{ fill: "red" }} />
                ) : (
                  <FavoriteBorderOutlinedIcon className="favourite-outline-icon" />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.casinoCardText}>

          <Grid className={'text-truncate'}>
            <Typography variant="h6" gutterBottom className="game-name">
              {game?.name}
            </Typography>
            <Typography>
              {game?.CasinoProvider?.name || streamer.name}
            </Typography>

          </Grid>
          <Link
            href="javascript:void(0);"
            onMouseEnter={(e) => setShowIcard(e.currentTarget)}
            onMouseLeave={() => setShowIcard(null)}
            onTouchStart={(e) => setShowIcard(e.currentTarget)}
            onTouchEnd={() => setShowIcard(null)}
          >
            <InfoIcon />
            <ICards
              anchorEl={showICard}
              setOpen={setShowIcard}
              renderData={{
                [t("gameName")]: game?.name,
                [t("providerName")]: game?.CasinoProvider?.name,
                [t("subCategoryName")]: startCase(toLower(renderGameSubCategory)),
              }}
            />
          </Link>

        </Grid>
      </Grid>

      <NotSigninDialog ref={NotSigninDialogRef} />
      <InSufficiantBalDialog ref={InSufficiantBalDialogRef} />
    </>
  );
};


export const GameCardWinner = ({ winnerDetails, gameCardClassName, showPlayButton }) => {
  const { CasinoGame: game, User, winAmount, betAmount } = winnerDetails;

  const classes = useStyles();

  const WinnerAmountContent = () => {
    return (
      <div className={classes.winnerAmountContent}>
        <Typography variant="h6" gutterBottom className="game-name">
          {winAmount.toFixed(2)} {User?.currencyCode}
        </Typography>
        <Typography>{User?.username}</Typography>
      </div>
    )
  }
  return (
    <Grid className="game-card-winner">

      <GameCard
        winnerAmountContent={<WinnerAmountContent />}
        {...{ game, className: gameCardClassName }}
        showPlayButton={showPlayButton}
      />
    </Grid>
  )
}


const SearchGameCards = () => {
  const classes = useStyles();
  const { searchedGamesList } = useSelector((state) => state.casino);
  return (
    <Grid className={classes.searchGamesWrap}>
      {searchedGamesList &&
        searchedGamesList.rows.length > 0 &&
        searchedGamesList.rows.map((game) => (
          <>
            <GameCard key={game.id} game={game} />
          </>
        ))}
    </Grid>
  );
};

export const ViewAllGameCards = () => {
  const casino = useCasinoState(useSelector);
  const classes = useStyles();

  return (
    <Grid className={`${classes.searchGamesWrap} ${classes.viewAllPageWrap}`}>
      {casino &&
        casino?.gamesList?.rows?.length > 0 &&
        casino?.gamesList?.rows?.map((game) => (
          <>
            <GameCard key={game?.id} game={game} />
          </>
        ))}
    </Grid>
  );
};

export function GamecardPage({ isSearchGameCards = false }) {
  return <>{isSearchGameCards ? <SearchGameCards /> : <ViewAllGameCards />}</>;
}
