// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mustbeAtleast3Characters:
    "{{label}} deve ter pelo menos {{minNumber}} caracteres",
  cannotExceed32Characters:
    "{{label}} não pode exceder {{maxNumber}} caracteres",
  cannotExceed15Characters:
    "{{label}} não pode exceder {{maxNumber}} caracteres",
  isRequired: "{{text}} é necessário",
  invalidEmailFormat: "Formato de email inválido",
  loggedInSuccess: "Conectado com sucesso",
  loggedOutSuccess: "Desconectado com sucesso",
  internalServerError: "Erro Interno no Servidor ",
  userAlreadyExists: "Usuário já existe",
  userNotExists: "O usuário não existe",
  unAuthorized: "Não autorizado",
  usernameOrEmailNotMatch: "Usuário/e-mail ou senha não corresponde",
  usernameOrEmail: "Usuário/e-mail",
  username: "Nome de Usuário",
  firstName: "Nome",
  lastName: "Sobrenome",
  password: "Senha",
  forgotPassword: "Esqueceu sua senha?",
  casino: "Cassino",
  sportsbook: "Apostas esportivas",
  promotions: "Promoções",
  winners: "Vencedores",
  searchGames: "Pesquisar",
  login: "Entrar",
  signup: "Registrar",
  cancel: "Cancelar",
  balance: "Saldo",
  bonus: "Bônus",
  deposit: "Depósito",
  withdraw: "Retirar",
  withdrawRequest: "Solicitação de retirada",
  logout: "Sair",
  transactionReport: "Relatório de transações",
  bettingSlipReport: "Relatório de boletim de apostas",
  category: "Categoria",
  support: "Suporte",
  liveSupport: "Suporte ao vivo",
  supportCenter: "Central de Ajuda",
  contactUs: "Contate-nos",
  faq: "Perguntas frequentes",
  tnc: "Termos",
  privacyPolicy: "Política de Privacidade",
  cookiePolicy: "Política de Cookies",
  bonusRules: "Regras de bônus",
  socialMedia: "Mídia social",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  discord: "Discord",
  aboutUs: "Sobre nós",
  aboutOnlybets: "Sobre apenas apostas",
  affiliates: "Afiliados",
  responsibleGaming: "Jogo Responsável",
  introduction: "Introdução",
  settingLimits: "Limites",
  paymentMethods: "Métodos de pagamento seguros",
  popularProviders: "Provedores mais populares",
  copyrightMessage: "Direitos autorais 2023 Todos os direitos reservados. ",
  enterEmail: "Digite seu endereço de e-mail",
  enterFirstName: "Digite seu primeiro nome",
  enterLastName: "Digite seu último nome",
  enterUserName: "Digite seu nome de usuário",
  sendEmail: "Enviar E-mail",
  personalInformation: "Informações pessoais",
  CPF: "CPF",
  continue: "Continuar",
  nameAndSurname: "Nome e sobrenome",
  dateOfBirth: "Data de nascimento",
  goBack: "Volte",
  email: "E-mail",
  telephone: "Telefone",
  eighteenPlus: "Eu tenho mais de 18 anos",
  acceptTnC: "Termos e condições ",
  profile: "Perfil",
  loyality: "Fidelidade",
  name: "Nome",
  surname: "Sobrenome",
  gender: "Gênero",
  dob: "Data de nascimento",
  emailAddress: "Endereço de email",
  language: "Linguagem",
  timeZone: "Fuso horário",
  update: "Atualizar",
  confirmPassword: "Confirme sua senha",
  updatePassword: "Atualizar senha",
  address: "Endereço",
  residingCounty: "País de residência",
  residingState: "Estado de residência",
  residingCity: "Cidade residente",
  pincode: "Código PIN",
  cep: "CEP",
  notinform: "Prefiero não informar",
  other: "Outro",
  userBalance: "SALDO DO USUÁRIO",
  bonusBalance: "Saldo de bônus disponível para apostas",
  activeBonuses: "Bônus Ativos",
  fulfilledBonuses: "Bônus Recebidos",
  forfeitedBonuses: "Bônus perdidos",
  paymentType: "TIPO DE PAGAMENTO",
  cryptoCurrencies: "CRIPTOMOEDAS",
  depositP1: "Pix é o pagamento instantâneo brasileiro.",
  depositP2:
    "Meio de pagamento criado pelo Banco Central (BC) no qual os recursos são transferidos entre contas em poucos segundos, a qualquer hora ou dia.",
  depositP3: "É prático, rápido e seguro. ",
  minimum: "Mínimo",
  maximum: "Máximo",
  processingTime: "Tempo de processamento 5 minutos",
  value: "Valor",
  payment_pix: "Pagamento por Pix",
  payment_credit: "Pagamento por cartão de crédito",
  random_key: "Chave aleatória",
  key: "Chave",
  currency: "Moeda",
  copiedSuccessfully: "Copiado com sucesso!",
  cpf: "CPF",
  pix_key_type: "Tipo de chave Pix",
  pix_key: "Chave Pix",
  pix_qr_code: "Código QR PIX",
  pix_code: "Código PIX",
  copy_pix_code: "Copiar código PIX",
  expired_qr_code: "Código QR expirado!",
  new_qr_code: "Novo QR code?",
  pix_instructions_title: "Instruções para o código PIX",
  pix_instructions_item_1: "1. Acesse a área pix do seu aplicativo bancário",
  pix_instructions_item_2:
    "2. Escolha a opção ‘Ler código QR’ e utilize a câmera do seu celular para escanear o código ou copie o código alfanumérico alternativo (formado por números e letras) e cole na opção ‘PIX Copia e Cola’ do aplicativo.",
  pix_instructions_item_3: "3. Confirme o pagamento na sua conta do jogo.",
  successWithdrawalRequest: "Solicitação de retirada bem-sucedida",
  withdrawalRequest: "SOLICITAR SAQUE",
  invalidCpfNumber: "Número de CPF inválido",
  invalidEmail: "E-mail inválido",
  invalidPhoneNumber: "Número de telefone inválido",
  invalidPixRandomKey: "A chave pix aleatória não é válida",
  date: "Data",
  time: "tempo",
  noResultsFound: "Nenhum resultado encontrado",
  search: "Procurar",
  knowledgeBase: "Acesse nossa base de conhecimentos.",

  withdrawal: "Solicitação de saque",
  withdrawalP1: "Você não pode realizar saques para terceiros.",
  withdrawalP11: "Os saques só são permitidos para contas da sua própria titularidade e também são limitados a 1 solicitação por dia.",
  ERC20Address: "Seu endereço ERC20",
  withdrawalP2:
    "Certifique-se de NÃO inserir o endereço BEP2, BEP20 (BSC) como endereço de retirada de carteiras LTC",
  withdrawalP3: "Por favor, NÃO retire como transferência entre cadeias",
  withdrawalFee: "Taxa de retirada",
  amount: "Montante",
  status: "Status",
  transactionType: "Tipo de transação",
  bettingSlipId: "ID do boletim de apostas",
  livePreMatch: " Ao vivo/pré-jogo:",
  bettingSlipState: "Estado do boletim de apostas",
  playingNow: "Jogando agora",
  playNow: "Jogar agora",
  loyalty: "Fidelidade",
  myAccount: "Minha conta",
  reports: "Relatórios",


  // Banner 1
  playWithYourFavoriteInfluencer: "Jogue com o seu Influencer Favorito!",
  signUpWith100DollarBonus: "Registre-se agora e ganhe até R$ 100,00 em bônus no seu primeiro depósito.",
  registerNow: "Junte-se a Nós!",


  // Banner 2
  welcomePackage: "Pacote de Boas-Vindas de até R$ 100,00",
  ClickToFirstDeposit: "Clique no botão abaixo e ganhe um bônus imperdível de até R$ 100,00 em seu primeiro depósito.",
  wantBonus: "Quero Meus Bônus",


  // Banner 3
  everyTimeReward: "Sempre que jogar conosco, você será recompensado!",
  knowLoyalty: "Conheça nosso programa de fidelidade exclusivo",
  moreInfo: "Saiba Mais",




  // Banner 1 Cassino ao vivo
  winWithInfluencer: "Conquiste vitórias ao lado do seu Influencer Favorito!",
  signUpNowGetBonus: "Cadastre-se agora e ganhe um bônus imperdível de até R$ 100,00 em seu primeiro depósito.",
  registerNow: "Cadastre-se Agora! ",


  // Banner 2 Cassino ao vivo
  feelCasinoAtmosphere: "Sinta a atmosfera do cassino em casa!",
  exploreLiveGames: "Explore nossa seleção de jogos ao vivo e mergulhe na diversão.",
  wantParticipate: "Quero Participar!",


  // Banner 3 Cassino ao vivo
  realOpponents: "Desafie oponentes reais!",
  livePoker: "Entre na ação do poker ao vivo e mostre suas habilidades.",
  registerNow: "Cadastre-se Agora!",



  home: "Home",
  games: "Jogos",
  influencers: "Influenciadores",
  recommended: "Recomendado",
  slots: "Caça-níqueis",
  jackpots: "Jackpots",
  imLucky: "Estou com Sorte",
  readMore: "consulte Mais informação",
  recentWinners: "Vencedores recentes",
  recentLargeMultiplayers: "Grandes multijogadores recentes",
  providers: "Provedores",
  pleaseAddMinimum: "* Por favor, adicione o mínimo",
  filter: "Filtro",
  noResultFound: "Nenhum resultado encontrado",
  type: "Tipo",
  yourFirstTime: "Sua primeira vez?",
  doubleBankroll: "Banca Dupla! ",
  bonusFistTime: "Bônus de até R$ 100",
  descriptionHome: "e jogue juntos com seus influencers preferidos.",
  fistTimeDeposit: ", em seu primeiro depósito.",
  textHome: "Faça seu primeiro depósito ",
  reliableSystem: "Website Confiável",
  bonusUpTo1000: "Plataforma 100% Confiável",
  fastAndSecurePayment: "Saque Rápido",
  quickRegistration: "Registro rápido",
  whatOnlybetsOffersYour: "O QUE A ONLYBETS OFERECE A VOCÊ",
  differentialTreatmentNote: "Suporte 24/7 ",
  loyaltyProgram: "Programa de Fidelidade",
  bigPrizes: "Altas Premiações Semanais",
  playWithInfluencer: "Jogue com Influenciadores",
  newWeeklyGames: "Novos Jogos Semanais",
  bonusAndCupons: "Bônus e Cupons a todo momento",
  almostOnTheSpot: "Quase no local",
  youPayReallyHighAmounts: "Você paga quantias muito altas",
  highReturns: "Altos retornos",
  newGamesWeekly: "Novos jogos semanais",
  chooseSomeoneToPlayWithYou: "TENHA ALGUÉM PARA JOGAR COM VOCÊ!",
  promotion1:
    "Na OnlyBets, a principal diferença é que você nunca estará sozinho. ",
  promotion2: "Os Resultados são muito maiores quando você tem alguém te incentivando!",
  hereYouAreAPriority: "Aqui, você é nossa prioridade.",
  twentyFourHourSupport: "Suporte 24 horas.",
  promotion3:
    "Se você tiver algum problema, sempre haverá alguém para ajudá-lo.",
  othersSpeakForUs: "AGORA DEIXAREI OS OUTROS FALAREM POR NÓS",
  betinasAreWaitingForYouComeNow: "BETINAS ESTÃO ESPERANDO VOCÊ, VENHA AGORA,",
  promotion4:
    "As Betinas estão morrendo de vontade de ver você lucrar junto com elas",
  weWantYourGood: "QUEREMOS O SEU BEM!",
  support24x7: "SUPORTE 24 horas por dia, 7 dias por semana",
  weWillGetBackToYouInRecordTime:
    "Entraremos em contato com você em tempo recorde!",
  languages: "idiomas",
  doesOnlybetsOfferFirstDepositBonuses:
    "A ONLYBETS OFERECE BÔNUS DE PRIMEIRO DEPÓSITO?",
  firstDepositBonus: "Sim! ",
  isTheWithdrawalFast: "A RETIRADA É RÁPIDA?",
  withdrawalSpeed:
    "Sim, nossa prioridade é conseguir seu dinheiro o mais rápido possível para que você possa aproveitá-lo o mais rápido possível.",
  doIHaveAccessToSupport: "TENHO ACESSO AO SUPORTE?",
  supportAccess:
    "Você terá acesso ao suporte VIP 24 horas por dia para responder às suas dúvidas ou resolver quaisquer problemas que possa ter.",
  areYouSureYouWantToLogout: "Tem certeza que deseja sair ?",
  yes: "Sim",
  no: "Não",
  cpfIsRequired: "*  CPF é obrigatório",
  nameIsRequired: "*  Nome é obrigatório",
  specialCharactersAreNotAllowed: "* Caracteres especiais não são permitidos",
  dateOfBirthCannotBeInTheFuture:
    "*  A data de nascimento não pode ser no futuro",
  shouldBeGreaterThanEighteenYears: "* Deve ser maior de 18 anos",
  dateOfBirthIsRequired: "* Data de nascimento é obrigatória",
  usernameOrEmailIsRequired: "* Usuário/e-mail é obrigatório",
  invalidEmailAddress: "* Endereço de email inválido",
  emailIsRequired: "* E-mail é obrigatório",
  passwordIsRequired: "* Senha é obrigatória",
  passwordMustBeAtLeastEightCharacters:
    "* A senha deve conter pelo menos 8 caracteres",
  passwordMustIncludeAtLeastOneUppercaseLowercaseNumber:
    "* A senha deve incluir pelo menos uma letra maiúscula, uma letra minúscula e um número",
  phoneNumberIsNotValid: "* Número de telefone não é válido",
  phoneNumberIsRequired: "* Número de telefone é obrigatório",
  youMustBeEighteenPlus: "* Você deve ter 18 anos",
  youMustAgreeToTheTermsAndConditions:
    "* Você deve concordar com os termos e condições",
  reCaptchaShouldBeVerified: "* ReCaptcha deve ser verificado",
  youMustVerifyTheReCaptcha: "* Você deve verificar o recaptcha",
  genderIsRequired: "* Sexo é obrigatório",
  surnameIsRequired: "* O sobrenome é obrigatório",
  usernameIsTooLong: "* O Usuário é muito longo",
  usernameIsRequired: "* Nome de usuário é obrigatório",
  affiliateTypeIsRequired: "* O tipo de afiliado é obrigatório",
  usernameInvalidCharacters: "* Somente letras, números e _ são permitidos",
  register: "Cadastrar",
  usernameIsShort: "* O nome de Usuário é muito pequeno!",
  registrationCompleteDialogText:
    "Enviamos um link de verificação em sua conta de e-mail registrada. ",
  ok: "OK",
  categories: "Categorias",
  didYouKnow: "Você sabia?..",
  youCanUseOneOfMore:
    "Você pode usar um ou mais filtros no menu para pesquisar\n  ",
  gameSearch: "Pesquisa de jogos",
  all: "Todos",
  viewAll: "Ver",
  liveCasino: "Cassino Ao Vivo",
  comingSoon: "Em breve",
  comingNewUpdate: "Estamos trabalhando em algo emocionante. ",
  scrollUp: "Role para cima para ver mais jogos...",
  loadMore: "Carregue mais",
  level: "Nível {{number}}",
  points: "Pontos {{number}}",
  loyaltyPoints: "Pontos de fidelidade {{number}}",
  coins: "Moedas {{number}}",
  loyaltyCoins: "Moedas de Fidelidade {{number}}",
  perPoint: "Por ponto",
  question1: "Como funciona o sistema de fidelidade OnlyBets",
  answer1:
    "Assim que você jogar sua primeira rodada no OnlyBets, você começará a ganhar pontos de fidelidade e moedas de fidelidade. ",
  necessoryLoyaltyPoints:
    "Total de pontos de fidelidade para alcançar o próximo nível",
  loyaltyCoinsEarnedPerLoyaltyPoint:
    "Moedas de fidelidade ganhas por ponto de fidelidade",
  maxLevel: "Nível máximo",
  claimNow: "Reinvindicar agora",
  enterValue: "Digitar {{value}}",
  valueCantGreaterthan:
    " Insira o valor que não pode ser maior que o existente {{type}} {{value}} {{currency}}",
  userLoyalty: "Fidelização do usuário",
  progressionSystem: "Sistema de progressão",
  joinNow: "Entrar",
  tryForFree: "Experimentar gratuitamente",
  gameName: "Nome do jogo",
  providerName: "Fornecedor",
  reset: "Reiniciar",
  uploadDocument: "Carregar documento",
  documentUploadedSuccessfully: "Documento enviado com sucesso",
  manageYourKyc: "Gerencie seu KYC",
  canNotUploadAgain:
    "Depois de carregado o arquivo, não é possível carregá-lo novamente ",
  kycStatus: "Seu arquivo é {{status}} por administrador",
  fileAllowed:
    "Por favor carregue apenas {{files}} arquivos com tamanho permitido {{size}}",
  fileAllowedFormat: "Por favor carregue apenas {{files}} arquivos",
  fileAllowedSize: "O tamanho do arquivo excede o limite {{fileSize}}. ",
  categoryName: "Nome da Categoria",
  contactWithAdmin: "Entre em contato com o administrador",
  referAFriend: "Indique seus Amigos.",
  copyTitleMessage: "Por favor copie {{title}} e envie para seus amigos.",
  yourRefferalCode: "Seu código de referência",
  refferalCode: "Código de referência",
  copiedMessage: "{text}} copiado com sucesso",
  shareInviteCode: "Compartilhe seu código de convite",
  resetPasswordLinkSentYourRegisterdEmail:
    "O link de redefinição de senha foi enviado para seu e-mail",
  resetPasswordLinkIsOnlyValidFor:
    "O link de redefinição de senha é válido apenas para {{time}}",
  resetPassword: "Redefinir senha",
  newPassword: "Nova Senha",
  passwordChangedSuccessfully: "Senha alterada com sucesso",
  wantLogin: "Você quer fazer login",
  tokenExpired: "Seu link de redefinição de senha expirou",
  verifyEmailAgain: "Você deve verificar seu e-mail novamente",
  verifyEmail: "Verificar e-mail",
  userInformation: " Informação do usuário",
  youDeserve: "Você merece ",
  ourBrandNew: "nossa novíssima",
  loyaltySystem: "Sistema de Fidelidade",
  playGameHandleRest:
    "Basta jogar os jogos que você adora e nós cuidaremos do resto!",
  earnCoinsSpinning: "Ganhe moedas girando",
  earnLoyaltyPointsCoinsOnPlayCasino:
    " Sempre que você jogar em nosso cassino ou cassino ao vivo, você ganhará Pontos de Fidelidade e Moedas de Fidelidade.",
  alwaysRewardAtOnlybets: "Você é sempre recompensado em OnlyBets",
  onlyBetsWeMakeSureThat: "Na OnlyBets garantimos que...",
  everySpinCounts: "...cada giro conta!",
  wantPlayerAlwaysRewarded:
    "Queremos garantir que os jogadores sejam sempre recompensados ​​ao jogar no OnlyBets. ",
  perentGameAward: "{{percent}} dos nossos jogos de cassino irão premiá-lo",
  wheneverWinLossLoyaltyPointAndCoins:
    "com Pontos de Fidelidade e Moedas de Fidelidade sempre que você joga, ganha ou perde!",
  lastLoginTime: "Hora do último login",
  loginFirst: "Você não está logado, faça login primeiro",
  prev: "Anterior",
  next: "Próximo",
  page: "Página",
  bonusCode: "Código Bônus",
  wwwOnlybets:
    " www.onlybets.tv é operado por OnlyBets B.V. (Registro Comercial de Curaçao nº 166128 Emancipatie Boulevard 31, Curaçao) sob uma sublicença CIL de acordo com a Licença Master de Jogo №5536/JAZ",
  antiMoneyLaundering: "Contra lavagem de dinheiro",
  siteUnderDevelopment: "O site está em desenvolvimento. ",
  earnuptoBRLOnPerInvitation: "Ganhe R$5 por cada indicação!",
  refreshPage: "Atualizar a página",
  inviteFriendandEarnMoney: "Convide um amigo e ganhe dinheiro",
  receiveCaseBalanceForEveryFriendYourRefer:
    "Receba R$5,00 assim que seu amigo realizar um depósito de R$20,00.",
  howInvitationWork: "Como funciona o convite",
  none: "Nenhum",
  debit: "Débito",
  credit: "Crédito",
  transactionBankingUUID: "UUID de transações bancárias",
  noDataAvailable: "Nenhum dado disponível",
  casinoTransactionId: "ID de transação do cassino",
  roundId: "ID da rodada",
  actionType: "Tipo de acão",
  win: "Ganhar",
  lose: "perder",
  live: "ao vivo",
  success: "Sucesso",
  pending: "Pendente",
  cancelled: "Cancelado",
  failed: "Fracassado",
  rollBack: "Reverter",
  approved: "Aprovado",
  rejected: "Rejeitado",
  requested: "Requeridos",
  removeMoney: "Remover dinheiro",
  addMoney: "Adicionar dinheiro",
  bettingHistoryReport: "Relatório de histórico de apostas",
  others: "Outros",
  onlybetsSystem: "Sistema Onlybets",
  uniqueSystemWithVIPSupport:
    "Claro que em um dos sites que mais cresceu nas últimas semanas haveria um sistema de afiliados para você ganhar dinheiro. ",
  record: "Registro",
  Affiliate: "Afiliado",
  Agency: "Agência",
  Streammer: "Streamer",
  idNo: "Nº de identificação",
  questionRegister: "Conte-nos o que te motiva a se tornar um afiliado do OnlyBets. Estamos curiosos para saber o que faz você querer se juntar a nós!",
  photoComment: "Envie uma foto em boa qualidade e resolução para destacar seu perfil.",
  placeHolderQuestionRegister: "Responda com no máximo 250 caracteres",

  Registrationform: "Formulário de registro",
  lastName: "Sobrenome",
  userName: "Usuário",
  submit: "Enviar",
  howItWorks: "Como funciona?",
  stepToSuccessFull:
    "É muito simples, basta seguir os três passos abaixo para ter sucesso",
  step: "Etapa {{number}}",
  affiliateLinkForOpenAccount: "Abra uma conta de afiliado e obtenha seu link.",
  promptYourLink: "Promova seu link usando a estratégia que você quiser",
  toWinEnjoyEarnings: "Ganhar. ",
  weWantTYourGoodness: "QUEREMOS O SEU BEM!",
  support24_7: "Suporte 24 horas por dia, 7 dias por semana",
  weServeYouInRecordTime: "Atendemos você em tempo recorde!",
  languageList: "Português, Inglês, Espanhol",
  languagePortuguese: "Português",
  languageEnglish: "Inglês",
  languageSpanish: "Espanhol",
  whoAreWe: "QUEM SOMOS NÓS?",
  ourDetails:
    "Nós da Onlybets nos orgulhamos de sermos abertos, transparentes e justos. ",
  chooseOnlyBets: "Escolha apenas apostas",
  highConversionRates: "Altas taxas de conversão",
  recordAchievingExcellentConversion:
    "Temos um histórico de obtenção de excelentes taxas de conversão com nossos parceiros. ",
  exceptionalPlayerValues: "Valores excepcionais do jogador",
  loyaltyBasedRetentionStrategy:
    "Nossa estratégia de retenção baseada em fidelidade provou gerar valor excepcional ao longo da vida de um jogador. ",
  greatCustomerSupport: "Ótimo suporte ao cliente",
  managersShowEarnPlayerLoyalty:
    "Nossos dedicados gerentes de afiliados mostrarão como ganhar a fidelidade dos jogadores e obter as comissões que você deseja. ",
  immediatePayments: "Pagamentos imediatos",
  chooseVariousPaymentMethods:
    "Escolha entre os vários métodos de pagamento que oferecemos no sistema. ",
  VIPTraining: "Treinamento VIP",
  whatYouWanToWin:
    "Queremos que você ganhe, e por isso entregaremos materiais exclusivos e treinamentos dedicados às suas mãos para que você ganhe mais!",
  subCategoryName: "Subcategoria",
  preferedCurrency: "Moeda",
  preferredCurrencyIsRequired: "A moeda preferencial é obrigatória",
  selectLanguage: "Selecione o idioma",
  selectTimezone: "Selecione o fuso horário",
  selectCountry: "Selecione o pais",
  kyc: "KYC",
  updateKYC: "Atualizar KYC",
  selectGender: "Selecione o gênero",
  male: "Masculino",
  female: "Feminino",
  currentPassword: "Senha atual",
  enter: "Digitar {{text}}",
  noSpaceAllow: "Espaço não é permitido",
  minCharacters: "Mínimo {{characterNo}} é permitido",
  maxCharacters: "Máx. {{characterNo}} é permitido",
  newPasswordTip:
    "A nova senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial",
  matchPassword: "A senha deve corresponder",
  updateProfile: "Atualizar perfil",
  profileUpdatedSuccessfully: "Perfil atualizado com sucesso",
  startAmount: "Valor inicial",
  endAmount: "Valor final",
  casinoBet: "Aposta de Cassino",
  casinoWin: "Vitória no cassino",
  applyFilterNote:
    "Nota: -Para aplicar o filtro, siga convenções como 'Data de início - Data de término', 'Hora de início - Hora de término', 'Valor inicial - Valor final'",
  bothAreRequired: "*  Começar {{text}} - Fim {{text}} ambos são obrigatórios",
  lessThen: "Menor que",
  greaterThen: "Maior que",
  dateShouldBeBetween:
    "{{dateType}} deveria estar entre {{minDate}} - {{maxDate}}",
  startCantGreaterThanEnd:
    "Começar {{text}} não pode ser maior que Fim {{text}}",
  startDate: "Data de início",
  endDate: "Data final",
  setOnlyRegistered:
    "*  A moeda pode ser selecionada apenas no horário cadastrado",
  favouriteGames: "Favoritos",
  sports: "Esportes",
  hello: "Olá",

  casinoWelcome: "Oferta de Boas-Vindas do Cassino",
  casinoWelcomeOffer: "Oferta de Boas-Vindas do Cassino",
  joinUsTodayToGetBonus:
    "Junte-se a nós hoje e receba um bônus no primeiro depósito de 100%, até R$ 100,00 para começar, basta depositar acima de R$ 20,00 na OnlyBets.",
  moreInformation: "Mais Informações",
  weeklyTournaments: "Torneio Semanais",
  immerseYourselfInTheEmbrace:
    "Mergulhe nos Jogos mais bombados do momento e aumenta em mais de 20x seus possíveis ganhos!",
  loyaltyProgram: "Programa de Fidelidade",
  WeWantToEnsureThatPlayers:
    "Queremos garantir que os jogadores sejam sempre recompensados ao jogar no Onlybets!",
  weekendCashbackBooster: "O impulsionador de cashback do fim de semana",
  puttingCashbackYourPocket:
    "Estamos colocando um cashback de 15% no seu bolso toda segunda-feira!",
  topWinners: "Principais vencedores",
  casinoWelcomeOffer: "Oferta de Boas-vindas ao Cassino",
  welcomeBonusAndSpins: " BÔNUS DE BOAS VINDAS 100%!",
  tncListItem1:
    "Estas regras complementam as Regras de Bônus e nossos Termos Gerais.",
  tncListItem2:
    "Promoção válida apenas para novos jogadores que nunca fizeram um depósito no cassino.",
  tncListItem3:
    "Para receber o bônus desta promoção, basta se cadastrar e realizar o seu depósito.",
  tncListItem4:
    "O bônus desta promoção é de 100%.",
  tncListItem5:
    "O valor máximo do bônus é de R$ 1000,00.",
  tncListItem6: "O requisito de aposta para esta promoção é de 35x o saldo do bônus.",
  tncListItem7:
    "O requisito de aposta deve ser cumprido em até 7 dias.",
  tncListItem8:
    "Caso o requisito de aposta não seja cumprido dentro do prazo, todos os fundos de bônus serão perdidos.",
  tncListItem9:
    "O depósito mínimo para qualificação é de R$ 1,00.",
  tncListItem10:
    "Promoção válida apenas uma vez por pessoa, domicílio, computador compartilhado ou endereço IP compartilhado.",
  tncListItem11:
    "Promoção não acumulável com outras promoções.",
  tncListItem12:
    "OnlyBets reserva-se o direito de restringir o acesso a esta promoção a qualquer jogador, por qualquer motivo que considerar adequado.",
  tncListItem13:
    "Em caso de discrepâncias entre as versões destes termos em diferentes idiomas, a versão em inglês prevalece.",
  // tncListItem14: "Esta promoção não é acumulável com outras promoções.",
  // tncListItem15:
  //   "Esta promoção não está disponível para jogadores que utilizam carteiras de criptomoedas.",
  // tncListItem16:
  //   "OnlyBets reserva-se o direito de restringir o acesso a esta promoção a qualquer jogador, por qualquer motivo que considere adequado.",
  // tncListItem17:
  //   "Em caso de inconsistências entre os diferentes idiomas destes termos e condições, prevalece a versão em inglês.",
  transformYourJourney: "Transforme sua jornada no cassino em uma experiência inesquecível com a OnlyBets!",
  listItem1:
    "Nosso atrativo Sistema de Fidelidade garante que você seja sempre recompensado por jogar.",
  listItem2:
    "Bônus de recarga e presentes-surpresa para manter a diversão rolando",
  listItem3: "Uma Experiência VIP como você nunca viu antes",
  introText: "Adentre o universo de diversão da OnlyBets!",
  welcomeOfferText:
    "Inicie sua aventura no cassino da OnlyBets com uma Oferta de Boas-Vindas espetacular, feita sob medida para campeões! Aproveite um incrível Bônus de Boas-Vindas de 100% até R$ 100,00 no seu primeiro depósito. Basta realizar um depósito acima de R$ 20,00. Parece interessante? ",
  moreExcitingTitle:
    "Prepare-se para a Emoção",
  moreExcitingText:
    "E tem mais! Descubra as surpresas que preparamos para tornar sua experiência inesquecível:",
  tournamentText:
    "Se isso não for suficiente, não se preocupe, nós cuidamos disso. Nossos Torneios semanais repletos de prêmios em DINHEIRO manterão sua adrenalina alta e seu entretenimento ainda maior!",
  goodLuckText:
    "Mas por que você está perdendo tempo conversando comigo quando tem mais de 6.000 jogos esperando por você? Boa sorte, novo amigo!",
  registerSuccessfull: "Você foi registrado com sucesso",
  checkMyProgress: "Checar meu progresso",
  promotionsTournamentsandMore: "Promoções, torneios e muito mais",
  enhanceYourGamingExperience: "Aumente sua experiência de jogo!",
  cashBalance: "Saldo de caixa",
  totalBalance: "Balanço total",
  inSufficientBalanceToPlay: "Você não tem fundos suficientes no momento. ",
  bonusReport: "Relatório de bônus",
  bonusName: "Nome do bônus",
  pending: "PENDENTE",
  active: "ATIVO",
  cancelled: "CANCELADO",
  forfeit: "PERDIDO",
  expired: "EXPIRADO",
  claiming: "REIVINDICAÇÃO",
  inprogress: "EM PROCESSO",
  lapsed: "CANCELADO",
  claimed: "REIVINDICADO",
  amountToWager: "Valor em apostas",
  expiryDate: "Data de expiração",
  action: "Ação",
  BonusCondition: "Para transformar o bônus em saldo real somados ao seu saldo, é necessário superar o valor em apostas.",

  casinoWelcomeOffer: "Oferta de Boas-vindas ao Cassino",
  welcomeBonusAndSpins: " BÔNUS DE BOAS-VINDAS 100%! ",

  listItem1:
    "Sistema de Fidelidade Excepcional: Jogue e seja recompensado por cada avanço de nível.",
  listItem2:
    "Bônus de Recarga e Presentes-Surpresa: A diversão nunca para com nossos bônus e brindes especiais.",
  listItem3: "Experiência VIP Exclusiva: Desfrute de um tratamento VIP inigualável e benefícios exclusivos.",
  introText: "Adentre o universo de diversão da OnlyBets!",

  tournamentText:
    "Se isso não for suficiente, fique tranquilo! Nossos torneios semanais, repletos de prêmios em dinheiro, manterão sua adrenalina alta e seu entretenimento garantido!",
  goodLuckText:
    "Por que esperar? Com mais de 6.000 jogos te aguardando, a diversão começa agora.",
  registerSuccessfull: "Você foi registrado com sucesso",
  checkMyProgress: "Checar meu progresso",
  promotionsTournamentsandMore: "Promoções, torneios e muito mais",
  enhanceYourGamingExperience: "Aumente sua experiência de jogo!",
  cashBalance: "Saldo de caixa",
  totalBalance: "Balanço total",
  inSufficientBalanceToPlay: "Você não tem fundos suficientes no momento. ",
  bonusReport: "Relatório de bônus",
  bonusName: "Nome do bônus",



  pending: "PENDENTE",
  active: "ATIVO",
  cancelled: "CANCELADO",
  forfeit: "PERDIDO",
  expired: "EXPIRADO",
  claiming: "REIVINDICAÇÃO",
  inprogress: "EM PROCESSO",
  lapsed: "CANCELADO",
  claimed: "REIVINDICADO",
  AffiliateType: "Tipo de afiliado",
  EmailNotVerifiedError: "E-mail não verificado",
  ThereIsAPendingWithdrawRequestError: "Já existe um pedido de saque pendente.",
  PaymentProviderError: "Ocorreu um erro ao encontrar o provedor de pagamento",
  UserIdentificationError:
    "Ocorreu um erro ao validar os dados de identificação do usuário",
  CpfValidationError: "Ocorreu um erro ao validar o CPF do usuário",
  PixCpfMustBeSameRegisteredError:
    "CPF informado para saque deve ser igual ao cadastrado",
  WalletNotFound: "Carteira não encontrada",
  BalanceError: "Saldo insuficiente",
  CpfInvalidError: "O número do CPF não é válido",
  CpfIrregularError: "Número de CPF com situação irregular",
  CashOutLimitPerDayError:
    "Saque - Limite de transação é 1 por documento por dia",
  TransactionRepeatedInLess10MinutesError:
    "Transação repetida em menos de 10 minutos.",
  WithdrawDataError: "Ocorreu um erro ao gerar dados de saque.",
  StreamingApiconnectEconnrefusedError:
    "Não foi possível conectar ao servidor de streaming",
  IncorrectCurrentPasswordErrorType: "Senha atual incorreta.",



  EmailNotVerifiedError: "E-mail não verificado",
  ThereIsAPendingWithdrawRequestError: "Já existe um pedido de saque pendente.",
  PaymentProviderError: "Ocorreu um erro ao encontrar o provedor de pagamento",
  UserIdentificationError:
    "Ocorreu um erro ao validar os dados de identificação do usuário",
  CpfValidationError: "Ocorreu um erro ao validar o CPF do usuário",
  PixCpfMustBeSameRegisteredError:
    "CPF informado para saque deve ser igual ao cadastrado",
  WalletNotFound: "Carteira não encontrada",
  BalanceError: "Saldo insuficiente",
  CpfInvalidError: "O número do CPF não é válido",
  CpfIrregularError: "Número de CPF com situação irregular",
  CashOutLimitPerDayError:
    "Saque - Limite de transação é 1 por documento por dia",
  TransactionRepeatedInLess10MinutesError:
    "Transação repetida em menos de 10 minutos.",
  WithdrawDataError: "Ocorreu um erro ao gerar dados de saque.",
  StreamingApiconnectEconnrefusedError:
    "Não foi possível conectar ao servidor de streaming",

  andGrowing: "e crescendo!",
  refund: "Reembolso",
  bet: "Aposta",
  depositBonusClaim: "Reivindicação de bônus de depósito",
  referralBonusClaim: "Reivindicação de bônus de indicação",
  joiningBonusClaim: "Participando da reivindicação de bônus",
  joiningBonus: "Bônus de adesão",
  referralBonus: "Bônus de referência",
  depositBonus: "Bônus de Depósito",
  pageNotFound: "Não encontramos a página que você procura!",
  goToHome: "Vá para casa",
  ooops: "Ops",
  redirectingMessage: "Redirecionando para {{redirectTime}} segundos...",

  volatility: "Volatilidade",
  themes: "Temas",
  sportsTransactionReport: "Relatório de transações esportivas",
  transactionId: "ID da transação",
  rollback: "Reverter",
  rollbackbeforebetwin: "Rollback antes de apostar/ganhar",
  freespins: "Rodadas grátis",
  betInternal: "Aposta Interna",
  winInternal: "Ganhe interno",
  discard: "Descartar",
  settlement: "Povoado",
  refund: "Reembolso",

  CpfCanceledError: "Número de CPF cancelado",
  CpfNotFoundError: "Número de CPF não encontrado",
  CpfUnderAgeError: "O número do CPF é menor de idade",

  UserNameExists: "Já existe um usuário cadastrado com esse nome",
  UserAlreadyExistErrorType: "Já existe um usuário cadastrado com esse E-mail",

  signUpSuccessful: "Usuário cadastrado com sucesso!",
  CpfAlreadyExists: "Já existe um usuário cadastrado com esse número de CPF",

  bonusExpiresIn: "Bônus expira em:",
  amountToBeDeposited: "Valor a ser depositado:",
  doYouHaveACoupon: "Você tem um cupom?",
  generatePix: "Gerar PIX",
  amountMinValue: "Valor mínimo de depósito é {{minimumDeposit}}",
  maximumDeposit: "Valor máximo de depósito é {{maximumWithdraw}}",
  applyBonus: "Aplicar bônus",
  paymentProvider: "Provedor de pagamento",
  scanTheImageToMakePayment: "Escaneie a imagem para realizar o pagamento",

  payment_processed_successfully: "Pagamento processado com sucesso!",
  UserAlreadyExists: "Usuário já existe!",

  BonusNotFound: "Código de bônus não encontrado!",
  BonusTypeAlreadyActiveErrorType: "Você já tem um bônus ativo desse tipo!",

  reffCode: "Código de referência",
  apply: "Aplicar",

  withdrawRequestRule1: "Para realizar o seu primeiro saque, é necessário apostar um valor correspondente a {{rolloverMultiplier}} vezes o montante do seu depósito inicial. Esse critério deve ser cumprido para prosseguir com a retirada dos fundos.",
  withdrawRequestRule2: "Exemplo: Após um depósito inicial de {{depositAmountExample}}, É necessário apostar um mínimo de R$ {{rolloverMultiplierAmount}} em jogos de cassino ou apostas esportivas para liberar o saque. Este requisito é válido somente para o primeiro saque.",

  withdrawRequestRule3: "Para realizar seu saque, é necessário apostar um valor correspondente a {{rolloverMultiplier}} vezes o montante dos seus depósitos. Esse critério deve ser cumprido para prosseguir com a retirada dos fundos.",
  withdrawRequestRule4: "Exemplo: Suponhamos que o valor dos seus depósitos tenha sido {{depositAmountExample}}, É necessário apostar um mínimo de {{rolloverMultiplierAmount}} em jogos de cassino ou apostas esportivas para liberar o saque. Esse requisito é valido para a soma total dos seus depósitos.",

  UserNotAllowedWithdrawError: "Solicitação de saque não liberada",
  PixKeyDontBelongToDocumentErrorType: "Chave pix não pertence ao documento do usuário",
  FirstDepositAmountLimitError: "O limite para o primeiro depósito é de até {{maximumFirstDeposit}}",
  INCORRECT_CREDENTIAL: "Este e-mail/senha está incorreto",

  minimunMaximunDepositAmount: "Por favor adicione no mínimo {{minimumDeposit}} e no máximo {{maximumDeposit}}",

  wallet: "Carteira",
  viewBalanceInformation: "Ver informações sobre o saldo",
  maximumWithdrawalAmountExceeded: "Valor máximo para saque excedido",
  minimunMaximunWithdrawAmount: "Por favor adicione no mínimo {{minimumWithdraw}} e no máximo {{maximumWithdraw}}",
  balanceAvailableForWithdrawal: "Saldo real",


  CashOutMaxValueIs50000ByDocumentPerDayErrorType: "O valor máximo de saque é R$ 50.000,00 por documento por dia",

  claimedSuccessfully: "Reivindicado com sucesso!",
  depositDouble: "Deposite agora e ganhe o dobro do seu depósito até {{maximumFirstDeposit}}",

  AffiliateAlreadyExists: "Já existe um usuário cadastrado!",
  EmailNotAvailableErrorType: "Endereço de e-mail não disponível!",

  selectAffiliateType: "Selecione o tipo de afiliado que melhor lhe representa",

  affiliate: "Afiliado",
  streamer: "Streamer",
  agency: "Agência",
  other: "Outro",

  // new
  affiliateRegistrationSentSuccessfully: "Cadastro enviado para aprovação, em breve entraremos em contato.",
  sentSuccessfully: "Enviado com sucesso!",
  sentRequest: "Enviar solicitação",
  reviewAllInformation: "Revise todas as informações que você digitou nas etapas anteriores e, se tudo estiver correto envie sua solicitação",
  youWillReceiveReturn: "Você receberá um retorno no prazo de 72 horas",

  registrationData: "Dados de Cadastro",
  pleaseEnterRealInformationForApproval: "Por favor, insira informações reais para aprovação.",

  tellUsWhatMotivatesYouToBecomeAnOnlybetsAffiliate: "Conte-nos o que te motiva a se tornar um afiliado da Onlybets.",
  wereCuriousToKnowWhatMakesYouWantToJoinUs: "Estamos curiosos para saber o que faz você querer se juntar a nós!",

  iHaveReadAndAcceptThePrivacyPolicy: "Li e aceito a Política de Privacidade",

  iWouldLikeToBeAnAffiliate: "Gostaria de ser um Afiliado",
  iWouldLikeToBeAnStreamer: "Gostaria de ser um Streamer",
  iWouldLikeToBeAnAgency: "Gostaria de ser uma Agência",
  iWouldLikeToBeAnOther: "Gostaria de ser outro tipo de afiliado",

  affiliateTypeAffiliateDescription: "Trazer novos usuários, motivá-los, usar minhas redes sociais e estratégias de marketing para atrair jogadores e ser comissionado por isso.",
  affiliateTypeStreamerDescription: "Além de trazer novos usuários, gostaria de utilizar meu carisma e conhecimento para falar com os jogadores e motivá-los através de transmissões ao vivo na plataforma",
  affiliateTypeAgencyDescription: "Pretendo trazer novos afliados e/ou streamers para a plataforma, garantindo um crescimento exponencial, mas não pretendo fazer transmissões ou motivar jogadores diretamente.",
  affiliateTypeOtherDescription: "Trazer novos usuários, motivá-los, usar minhas redes sociais e estratégias de marketing para atrair jogadores e ser comissionado por isso.",

  close: "Fechar",

  passwordValidationMessage: "A senha deve incluir no mínimo 8 caracteres com letras e/ou números e não deve incluir espaços.",

  yourProgress: "Seu progresso",
  completed: "Concluído",

  weNoticedThatYouAreNotYetConnectedToOurPlatform: "Notamos que ainda não está conectado a nossa plataforma.",
  toAccessChooseOneOfTheOptionsBelow: "Para acessar, escolha uma das opções abaixo:",

  iWantToRegister: "Quero me cadastrar",
  iAmAuser: "Já sou usuário",

  welcomeTo: "Bem vindo à",
  needHelp: "Precisa de ajuda?",
  clickHere: "Clique aqui",

  yourFirstTimeItsSafeAndEasyToUse: "Sua primeira vez?",
  makeYourFirstDepositAndPlayTogetherWithYourFavoriteInfluencers: "Faça seu cadastro e jogue agora com uma de nossas garotas.",
  makeAMinimumDepositToPlayWithTheInfluencer: "Faça um depósito para jogar com a sua Influenciadora",
  makeADeposit: "Fazer um depósito",

  girsFromAllOverBrazilWantToKeepYouCompany: "Garotas de todo o Brasil querem te fazer companhia",
  dontHaveAnyDoubtsWeAlwaysHaveSomeoneAvailableToHelpYou: "Não fique com dúvidas, temos sempre alguém disponível pra te ajudar.",

  wouldYouLikeToPlayWhileWatchingOneOfOurGirlsLive: "Gostaria de jogar assistindo a live de uma de nossas garotas?",
};
