import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  AccordionDetails,
  Typography,
  Button,
  DialogTitle,
  Accordion,
  AccordionSummary,
  Slide,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";

import useStyles, {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  useSearchStyle,
} from "./Searchgame.styles";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import modalImage from "../../../../assets/images/modal-img.svg";
import { fetchSearchedGamesThunk } from "redux-thunk/thunk/casino/casino.thunk";
import { useTranslation } from "react-i18next";
import { theme } from "theme/index";
import DidYouKnow from "./DidYouKnow";
import AppLoader from "components/ui-kit/AppLoader/index";
import { LOADER_HANDLER_TYPES } from "constants/index";
import { colors } from "theme/colors/index";
import { setOpenSearchPopup, setSearchedGamesList } from "redux-thunk/redux/casino/casino.slice";
import { isMobile } from "react-device-detect";
import { GameCard } from "components/ui-kit/Gamecard/index";
import Pagination from "components/ui-kit/Pagination/Pagination";

const Transition = React.forwardRef(function Transition (props, ref) {
  const transitionDuration = 1000;
  return (
    <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />
  );
});
const SearchGame = () => {
  const PROVIDER_STRING = "provider";
  const SUB_CATEGORY_STRING = "subcategory";
  const classes = useStyles();
  const searchClasses = useSearchStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState();
  const [providerListData, setProviderListData] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [gameProviderIds, setGameProviderIds] = useState([]);
  const [gameSubCategoryListData, setGameSubCategoryList] = useState([]);
  const [providerListPagination, setProviderListPagination] = useState({
    start: 0,
    end: 5,
  });
  const [gameSubCategoryListPagination, setGameSubCategoryListPagination] =
    useState({
      start: 0,
      end: 5,
    });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    limit: 8,
    totalPages: 0,
  });
  const [gameSubCategoryIds, setGameSubCategoryIds] = useState([]);

  const {
    searchedGamesList,
    gameProviderList,
    gameSubCategoryList,
    searchGamesLoader,
    openSearchPopup
  } = useSelector((state) => state.casino);
  const { language } = useSelector((state) => state.user);

  // handle dialog
  const handleClickOpen = () => {
    dispatch(setOpenSearchPopup(true))
  };
  const handleClose = () => {
    dispatch(setOpenSearchPopup(false))
    clearFilters();
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchValue(value);
      setPagination((prev) => {
        return {
          ...prev,
          currentPage: 1,
        };
      });
    }, 300),
    []
  );
  const handleOnSearch = useCallback(
    (e) => {
      const { value } = e.target;
      setSearch(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const clearSearch = () => {
    setSearch("");
    debouncedSearch("");
  };
  const isFilterNotApplied = () => {
    return searchTags, !search && !searchGamesLoader && !searchTags.length > 0;
  };

  const isFilterApplied = () => {
    return (
      (search && searchGamesLoader) ||
      (searchTags.length > 0 && searchGamesLoader)
    );
  };
  const clearFilters = () => {
    setSearch("");
    setSearchTags([]);
    setGameSubCategoryIds([])
    setGameProviderIds([])
    dispatch(setSearchedGamesList([]));
    setPagination((prev) => {
      return {
        ...prev,
        currentPage: 1,
        limit: 8,
        totalPages: 0,
      };
    });
  };

  const handlePageChange = (page) => {
    setPagination((prev) => {
      return {
        ...prev,
        currentPage: page,
      };
    });
  };

  const handleProviderOrCategoryClick = (item, type) => {
    let uniqueEntry;
    if (type === SUB_CATEGORY_STRING) {
      uniqueEntry = searchTags?.find(
        (entry) =>
          entry?.type === SUB_CATEGORY_STRING &&
          entry?.item?.gameSubCategoryId === item?.gameSubCategoryId
      );
    } else if (type === PROVIDER_STRING) {
      uniqueEntry = searchTags.find(
        (entry) =>
          entry?.type === PROVIDER_STRING &&
          entry?.item?.casino_provider_id === item?.casino_provider_id
      );
    }
    if (!uniqueEntry) {
      setSearchTags([...searchTags, { item, type }]);
      if (type === SUB_CATEGORY_STRING) {
        setGameSubCategoryIds([...gameSubCategoryIds, item?.gameSubCategoryId]);
      } else if (type === PROVIDER_STRING) {
        setGameProviderIds([...gameProviderIds, item?.casino_provider_id]);
      }
    }
  };

  const handleSearchTagRemoveClick = (item, index) => {
    setSearchTags((state) => {
      const items = state;
      items.splice(index, 1);
      return [...items];
    });
    if (item.type === PROVIDER_STRING) {
      setGameProviderIds (gameProviderIds?.filter(providerId=> providerId !== item?.item?.casino_provider_id))
    }
    else if (item.type === SUB_CATEGORY_STRING) {
      setGameSubCategoryIds (gameSubCategoryIds?.filter(subCategoryIds=> subCategoryIds !== item?.item?.gameSubCategoryId))
    }
  };
  const providerLanguageFilteredData = (arrData) => {
    return arrData?.map((gameProvider) => {
      return {
        ...gameProvider,
        name: gameProvider?.name?.[language?.toUpperCase()]
          ? gameProvider?.name?.[language?.toUpperCase()]
          : gameProvider?.name,
      };
    });
  };
  const categoryLanguageFilteredData = (arrData) => {
    return arrData?.map((gameSubCategory) => {
      return {
        ...gameSubCategory,
        name: JSON.parse(gameSubCategory?.name)?.[language?.toUpperCase()]
          ? JSON.parse(gameSubCategory?.name)?.[language?.toUpperCase()]
          : JSON.parse(gameSubCategory?.name)?.["EN"?.toUpperCase()],
      };
    });
  };
  const showMoreProviders = () => {
    const providersData = [...providerLanguageFilteredData(gameProviderList)];
    setProviderListData(
      providersData?.slice(
        providerListPagination?.start,
        providerListPagination?.end
      )
    );
  };
  const showMoreCategories = () => {
    const gameSubCategoryData = [
      ...categoryLanguageFilteredData(gameSubCategoryList),
    ];
    setGameSubCategoryList(
      gameSubCategoryData?.slice(
        gameSubCategoryListPagination?.start,
        gameSubCategoryListPagination?.end
      )
    );
  };
  const loadMoreProviders = () => {
    setProviderListPagination((prev) => {
      return {
        ...prev,
        end: prev?.end + 5,
      };
    });
  };
  const loadMoreCategories = () => {
    setGameSubCategoryListPagination((prev) => {
      return {
        ...prev,
        end: prev?.end + 5,
      };
    });
  };

  useEffect(() => {
    gameProviderList && showMoreProviders();
  }, [
    providerListPagination?.start,
    providerListPagination?.end,
    gameProviderList,
  ]);

  useEffect(() => {
    gameSubCategoryList && showMoreCategories();
  }, [
    gameSubCategoryListPagination?.start,
    gameSubCategoryListPagination?.end,
    gameSubCategoryList,
  ]);

  const fetchFilteredData = () => {
    if (
      gameProviderIds?.length === 0 &&
      gameSubCategoryIds?.length === 0 &&
      !search
    ) {
      return;
    }

    const payload = {
      subCategoryId: JSON.stringify(gameSubCategoryIds),
      search: debouncedSearchValue || "",
      pageNo: pagination.currentPage || 1,
      limit: pagination.limit || 8,
      isMobile,
    };
    if (gameProviderIds?.length>0) {
      payload.providerId = JSON.stringify(gameProviderIds);
    }
    dispatch(fetchSearchedGamesThunk({ payload }));
  };

  const debouncedFetchFilteredData = debounce(fetchFilteredData, 300);
  useEffect(() => {
    if (
      gameProviderIds?.length === 0 &&
      gameSubCategoryIds?.length === 0 &&
      !search
    ) {
      dispatch(setSearchedGamesList([]));
      return;
    }

    if (gameProviderIds?.length > 0 || gameSubCategoryIds?.length > 0) {
      debouncedFetchFilteredData();
    } else {
      debouncedFetchFilteredData();
    }
  }, [
    gameProviderIds,
    gameSubCategoryIds,
    pagination.currentPage,
    pagination.limit,
    isMobile,
    debouncedSearchValue,
  ]);

  const totalPages = useMemo(() => {
    const totalResults = searchedGamesList?.count || 0;
    const limit = pagination.limit || 8;
    return Math.ceil(totalResults / limit);
  }, [searchedGamesList, pagination.limit]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalPages: totalPages,
    }));
  }, [totalPages]);

  return (
    <Grid className={classes.searchParent}>
      <Search className={classes.headerSearch} onClick={handleClickOpen}>
        <SearchIconWrapper className="searchIcon">
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={t("searchGames")}
          inputProps={{ "aria-label": "search" }}
        />
      </Search>

      <Dialog
        open={openSearchPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        className={classes.searchGameModal}
      >
        <DialogTitle display="flex" className="modalHeading">
          <Typography variant="h6" sx={{ flexGrow: "1", textAlign: "center" }}>
            <b>{t("gameSearch")}</b>
          </Typography>

          <Grid sx={{ cursor: "pointer" }}>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </DialogTitle>

        <DialogContent className="modalBody">
          <Grid
            container
            gap={2}
            sx={{
              flexDirection: { xs: "column", sm: "row" },
            }}
            className="search-games-container"
          >
            <Grid
              className={classes.leftSection}
              sx={{
                width: { xs: "100%", md: "22%" },
              }}
            >
              <Grid className="web-category-list">
                <Grid className="custom-accordain-wrap">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{t("providers")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid className="search-tags-wrap">
                        <Grid className="search-tags-scroll">
                          {providerListData &&
                            providerListData?.map((provider) => (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleProviderOrCategoryClick(
                                    provider,
                                    "provider"
                                  );
                                }}
                                style={{ background: theme.colors.footerBg }}
                                className="btn-primary"
                              >
                                {provider.name}
                              </Button>
                            ))}
                        </Grid>
                        {gameProviderList?.length >
                          providerListPagination.end && (
                            <Button
                              onClick={loadMoreProviders}
                              style={{ background: theme.colors.btnSecondary }}
                              className="btn-secondary"
                            >
                              {t("loadMore")}
                            </Button>
                          )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>{t("categories")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid className="search-tags-wrap">
                        <Grid className="search-tags-scroll">
                          {gameSubCategoryListData &&
                            gameSubCategoryListData?.map((subCategory) => (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleProviderOrCategoryClick(
                                    subCategory,
                                    "subcategory"
                                  );
                                }}
                                style={{ background: theme.colors.footerBg }}
                                className="btn-primary"
                              >
                                {subCategory.name}
                              </Button>
                            ))}
                        </Grid>
                        {gameSubCategoryList?.length >
                          gameSubCategoryListPagination?.end && (
                            <Button
                              onClick={loadMoreCategories}
                              style={{ background: theme.colors.btnSecondary }}
                              className="btn-secondary"
                            >
                              {t("loadMore")}
                            </Button>
                          )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classes.rightSection}
              sx={{
                width: { xs: "100%", md: "74%" },
              }}
            >
              <Box sx={{ display: "flex" }} mt={1}>
                <Grid className={classes.searchContainer}>
                  <Box className={classes.input}>
                    <input
                      placeholder={t('gameSearch')}
                      onChange={handleOnSearch}
                      value={search}
                    />
                    {search && (
                      <CloseIcon
                        className={classes.clearButton}
                        onClick={clearSearch}
                      />
                    )}
                  </Box>
                  <Button
                    onClick={clearFilters}
                    className={`${classes.btnSecondary} ${classes.resetBtn}`}
                  >
                    {t("clear")}
                  </Button>
                </Grid>

              </Box>

              <Grid className="search-tags-wrap">
                {searchTags.map((tag, index) => {
                  return (
                    <Button className="line-btn">
                      {tag.item.name}
                      <CancelIcon
                        onClick={() => {
                          handleSearchTagRemoveClick(tag, index);
                        }}
                      />
                    </Button>
                  );
                })}
              </Grid>
              <Grid className="mob-category-list">
                <Grid className={classes.leftSection}>
                  <Grid className="custom-accordain-wrap">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{t("providers")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid className="search-tags-wrap">
                          <Grid className="search-tags-scroll">
                            {providerListData &&
                              providerListData?.map((provider) => (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleProviderOrCategoryClick(
                                      provider,
                                      "provider"
                                    );
                                  }}
                                  style={{ background: theme.colors.footerBg }}
                                  className="btn-primary"
                                >
                                  {provider.name}
                                </Button>
                              ))}
                          </Grid>
                          {gameProviderList?.length >
                            providerListPagination.end && (
                              <Button
                                onClick={loadMoreProviders}
                                style={{ background: theme.colors.btnSecondary }}
                                className="btn-secondary"
                              >
                                {t("loadMore")}
                              </Button>
                            )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>{t("categories")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid className="search-tags-wrap">
                          <Grid className="search-tags-scroll">
                            {gameSubCategoryListData &&
                              gameSubCategoryListData?.map((subCategory) => (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleProviderOrCategoryClick(
                                      subCategory,
                                      "subcategory"
                                    );
                                  }}
                                  style={{ background: theme.colors.footerBg }}
                                  className="btn-primary"
                                >
                                  {subCategory.name}
                                </Button>
                              ))}
                          </Grid>
                          {gameSubCategoryList?.length >
                            gameSubCategoryListPagination?.end && (
                              <Button
                                onClick={loadMoreCategories}
                                style={{ background: theme.colors.btnSecondary }}
                                className="btn-secondary"
                              >
                                {t("loadMore")}
                              </Button>
                            )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>

              </Grid>
              {isFilterNotApplied() ? (
                <DidYouKnow
                  image={modalImage}
                  title={t("didYouKnow")}
                  subtitle={t("youCanUseOneOfMore")}
                />
              ) : isFilterApplied() ? (
                <Box p={5}>
                  <AppLoader
                    variant={LOADER_HANDLER_TYPES.pulse}
                    color={colors.white}
                  />
                </Box>
              ) : searchedGamesList?.rows?.length > 0 ? (
                <>
                  <Grid
                    className={`${searchClasses?.searchGamesWrap} ${searchClasses?.viewAllPageWrap}`}
                  >
                    {searchedGamesList?.rows?.map((game, index) => (
                      <Box key={index}>
                        <GameCard game={game} />
                      </Box>
                    ))}
                  </Grid>
                  {pagination?.totalPages > 1 && (
                    <Grid className="custom-pagination-wrap">
                      <Pagination
                        currentPage={pagination?.currentPage}
                        totalPages={pagination?.totalPages}
                        onPageChange={handlePageChange}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <DidYouKnow image={modalImage} title={t("noResultsFound")} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default memo(SearchGame);
