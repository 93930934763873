import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({

  btn: {
    borderColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    fontSize: theme.spacing(0.875),
    boxShadow: "none",
    minWidth: "max-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    "&.primary": {
      background: theme.colors.btnPrimary,
      color: theme.colors.white,
      '&:hover': {
        background: theme.colors.btnPrimaryHover,
      }
    },
    "&.secondary": {
      background: theme.colors.btnSecondary,
      color: theme.colors.footerBg,
    },
    "&.tertiary": {
      background: theme.colors.footerBg,
      color: theme.colors.white,
    },
    "&.ghast": {
      background: "transparent",
      color: theme.colors.textColor,
      '&:hover': {
        background: "#0000001a",
        filter: "none",
      },
    },

    "&.white": {
      background: theme.colors.white,
      color: theme.colors.footerBg,
    },

    '&:hover': {
      filter: "brightness(1.1)",
    },

    "&.outline": {
      background: "transparent",
      "&.secondary": {
        color: theme.colors.btnSecondary,
        borderColor: theme.colors.btnSecondary,
        '&:hover': {
          background: theme.colors.btnSecondary,
          color: theme.colors.white,
        },
      },
      "&.white": {
        color: theme.colors.white,
        borderColor: theme.colors.white,
        '&:hover': {
          background: theme.colors.white,
          color: theme.colors.footerBg
        },
      },
      "&:hover": {
        filter: "none",
      }
    },

    "&.loading": {
      "& .text": {
        opacity: 0,
      },
      "& .loader": {
        display: "flex",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }
    },

    "&.Mui-disabled": {
      filter: "brightness(0.85)",
    },

    "& span.text": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "&[data-icon=true]": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "&[data-brightness=true]": {
      boxShadow: "0px 0px 2rem 0px #ffffffb0"
    },
  },
}))