import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { getVisitSessionCookie } from 'helpers/cookie.helpers'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import successMessages from 'network/messages/successMessages'
import { v4 as uuidv4 } from "uuid"

/**
 * Service to do login
 * @param {object} payload - object contains email and password
 */
export const userFirstVisit = (isFirstAccess) => {
  return axiosInstanceService(METHOD_TYPES.get, '/user/start', {}, {
    server: microServices.SERVICE_URL_1,
    headers: { 'is-first-access': true }
  })
}

/**
 * Service to do login
 * @param {object} payload - object contains email and password
 */
export const loginService = (payload) => {
  const visitSessionUUID = getVisitSessionCookie()

  return axiosInstanceService(METHOD_TYPES.post, '/user/login', payload, {
    server: microServices.SERVICE_URL_1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: successMessages.login,
    headers: { 'visit-session-uuid': visitSessionUUID }
  })
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstanceService(METHOD_TYPES.post, '/auth/logout', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: successMessages.logout
  })
}

/**
 * Service to fetch user information
*/
export const userInformationService = () => {
  return axiosInstanceService(METHOD_TYPES.get, '/user/get-user-details', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  }
  )
}

/**
 * Service to create new user
*/
export const userSignUpService = (payload) => {
  const visitSessionUUID = getVisitSessionCookie()
  return axiosInstanceService(METHOD_TYPES.post, '/user/sign-up', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    headers: { 'visit-session-uuid': visitSessionUUID }
  }
  )
}

/**
 * Service to send forget password email
 * @param {object} payload - object contains email
 */
export const forgotPasswordService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/forget-password', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to reset password
 * @param {object} payload - object contains password and newPasswordKey
 */
export const resetPasswordService = (payload) => {
  const visitSessionUUID = getVisitSessionCookie()
  return axiosInstanceService(METHOD_TYPES.post, '/user/verify-forget-password', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    showApiErrorMessage: false,
    headers: { 'visit-session-uuid': visitSessionUUID }
  })
}

/**
 * Service to create Affiliate
 * @param {object} payload - object contains firstName, lastName, email & username
 */

export const createAffiliateService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/create-affiliate', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

/**
 * Service to deposit amount in wallet
 * @param {object} payload - object contains
 */
export const depositAmountService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/payments/generateAddress', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to withdraw amount from wallet
 * @param {object} payload - object contains address, userId, amount
 */
export const withdrawAmountService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/payments/withdrawalRequest', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to get available currencies
 */
export const getAllCurrencies = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/payments/getAllCurrency', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to get available currencies
 */
export const getUserWithdrawRequests = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/payments/withdrawalRequests', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to validate the received token from ReCaptcha
 * @param {object} payload - object contains token
 *
 */
export const validateUserReCaptchaService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/verify-recaptcha-token', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updateUserProfileService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.put, 'user/update-user-details', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getSportsbookTokenService = () => {
  return axiosInstanceService(METHOD_TYPES.get, `user/sportsbook-token?id=${uuidv4()}`, null, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const resetUserPasswordService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/reset-old-password', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const verifyRegistrationEmailToken = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/verify-email', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const claimCoinsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/user/claim-coins', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const fetchKYCDocumentLabelService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/document/get-document-label', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const uploadKYCDocumentLabelService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.put, '/document/update-user-document', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const fetchUploadedKYCDocumentsLabelService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/document/get-documents', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}


export const updateLanguageService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.put, '/user/locale', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
