import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  FooterWrapper: {
    background: theme.colors.homeColor,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1, 0)
    },
    '& .custom-dropdown': {
      '& img': {
        width: theme.spacing(1.5627)
      },
      '& .MuiButton-root': {
        '&:hover': {
          color: theme.colors.white
        }
      }
    }
  },
  mainWrap: {
    padding: theme.spacing(2, 2, 0),
    maxWidth: '1600px',
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1, 0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1, 0),
    },
    //minHeight:"570px",
  },
  paymentOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.colors.btnSecondary}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
      paddingTop: theme.spacing(0),
      flexDirection: 'column',
      gap: theme.spacing(1)
    },
    '& p': {
      fontSize: theme.spacing(0.75),
      textTransform: "capitalize",
      color: theme.colors.winnersCardText
    }
  },

  popularOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5rem',
    padding: '2rem 0',
    marginTop: '3rem',
    marginBottom: '3rem',
    borderTop: `1px solid ${theme.colors.btnSecondary}`,
    borderBottom: `1px solid ${theme.colors.btnSecondary}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
      flexDirection: 'column',
      gap: '1rem'
    },
    '& p': {
      color: theme.colors.white
    }
  },
  popularLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: '1',
    flexWrap: 'wrap',
    gap: '3rem',
    '& img': {
      width: '100px'
    }
  },
  footerQuickLinks: {
    '& .MuiTypography-h4 ': {
      color: theme.colors.btnSecondary,
      fontSize: '0.875rem',
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(0.875),
        marginBottom: theme.spacing(0.625),
      },
    },
    '& a': {
      display: 'block',
      color: theme.colors.textColor,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.spacing(0.875),
      padding: '0.313rem 0',
      textDecoration: 'none',
      textTransform: 'capitalize',
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(0.75),
        padding: '0.1rem 0',
      },
      '&:hover': {
        color: theme.colors.btnSecondary
      }
    }
  },
  footerAboutWrap: {
    marginBottom: theme.spacing(2)
  },
  footerProviders: {
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    borderWidth: '1px 0 1px 0',
    border: `solid ${theme.border.secondaryBorder}`,
    '& .MuiTypography-body1': {
      color: theme.colors.textColor
    }
  },
  footerLogoSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: '0 0 1px 0',
    border: `solid ${theme.border.secondaryBorder}`,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    '& .MuiButton-root': {
      background: theme.colors.primaryDarkBlue,
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 'auto',
      '& span': {
        margin: theme.spacing(0, 0.5)
      }
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column!important'
    }
  },
  footerInstruction: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    '& svg': {
      // margin: theme.spacing(0, 2)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0)
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column!important',
      gap: '1rem'
    }
  },
  footerCopyright: {
    padding: theme.spacing(1),
    '& .MuiTypography-body1': {
      fontSize: theme.spacing(0.75),
      textAlign: 'center',
      color: theme.colors.copyRightText
    }

  },

  dropdownElementProfile: {
    '& .MuiList-root': {
      '& li': {
        '& svg': {
          width: '22px'
        },
        '& img': {
          width: '22px'
        }
      }
    },
    '& .MuiPaper-root': {
      border: `1px solid ${theme.colors.primaryLightBlue}`,
      background: theme.colors.primaryDarkBlue,
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.35)',
      // [theme.breakpoints.down('sm')]: {
      //   top: '66px !important',
      //   width: '95% !important',
      //   minWidth: '95% !important',
      //   left: '0 !important',
      //   right: '0px !important',
      //   margin: '0px auto !important',
      //   boxShadow: 'inherit',
      //   border: 'none',
      //   borderRadius: '0'
      // }
    },
    '& ul': {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
        margin: theme.spacing(0)
      },
      '& p': {
        background: theme.colors.primaryLightBlue,
        color: 'rgba(255, 255, 255)',
        padding: '4px 15px',
        textAlign: 'center',
        fontSize: '14px'
      },
      '& li': {
        color: theme.colors.white,
        fontFamily: '"IBM Plex Sans", sans-serif;',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        [theme.breakpoints.down('sm')]: {
          backgroundColor: theme.colors.themeCard,
          borderBottom: `1px solid ${theme.colors.primaryDarkBlue}`,
          minHeight: 'auto',
          lineHeight: '30px',
          fontSize: '12px'
        },
        '&.active': {
          backgroundColor: theme.colors.themeCard
        }
      }
    }
  }

}))
