import { Grid, Typography, Link, Button, Menu, MenuItem } from "@mui/material";
import { BrandLogoIcon } from "components/ui-kit/icons/iconComponents/BrandLogoIcon";
import { GamblersIcon, GambleAware, GamCare } from "components/ui-kit/icons/iconComponents/GamblersIcon";
import { AdulltIcon } from "components/ui-kit/icons/iconComponents/AdulltIcon";
import { EnIcon } from "components/ui-kit/icons/iconComponents/EnIcon";

import useStyles from "./Footer.styles";
import { NavLink } from "../../../../node_modules/react-router-dom/index";
import { LANGUAGES, ROUTE_PATHS } from "constants/index";
import payment_1 from "../../../assets/images/payment-method-1.svg";
import payment_2 from "../../../assets/images/payment-method-2.svg";
import payment_pix from "../../../assets/images/PIX.png";
import payment_5 from "../../../assets/images/payment-method-5.svg";
import payment_6 from "../../../assets/images/payment-method-6.svg";
import payment_7 from "../../../assets/images/payment-method-7.svg";

import provider_1 from "../../../assets/images/provider-1.svg";
import provider_2 from "../../../assets/images/provider-2.svg";
import provider_3 from "../../../assets/images/provider-3.svg";
import provider_4 from "../../../assets/images/provider-4.svg";
import provider_5 from "../../../assets/images/provider-5.svg";
import provider_6 from "../../../assets/images/provider-6.svg";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleLanguageChange } from "redux-thunk/redux/user/user.slice";
import * as SpanishIcon from "../../ui-kit/icons/svg/es.svg";
import * as PortugesIcon from "../../ui-kit/icons/svg/pt.svg";
import { DownArrowWhiteIcon } from "components/ui-kit/icons/iconComponents/DownArrowWhiteIcon";
import FooterLinks from "./components/FooterLinks";
import { fetchSocialLinksThunk } from "redux-thunk/thunk/footer/footer.thunk";
import { headerLinks } from "utils/common";
import licence_logo from "../../../assets/images/CIL.png";
import { fetchAllCmsData } from "redux-thunk/thunk/cms/cms.thunk";
import {
  getLocalLanguage,
  setLocalLanguage,
} from "helpers/localstorage.helpers";
import { colors } from "theme/colors/index";
import { updateLanguageThunk } from "redux-thunk/thunk/user/user.thunk";
import { getAuthToken } from "helpers/cookie.helpers";
import { Box } from "../../../../node_modules/@mui/material/index";

const GetIcon = ({ languageCode }) => {
  switch (languageCode) {
    case "en":
      return <EnIcon />;
    case "es":
      return <img height="27px" width="27px" src={SpanishIcon.default} />;
    case "pt":
      return <img width="34px" src={PortugesIcon.default} />;
    default:
      return <EnIcon />;
  }
};

const CustomeDropDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { language, userData } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageTitle, setLanguageTitle] = useState((state) => {
    const defaultLanguage = LANGUAGES.find(
      (lang) => lang.languageCode === state
    ) || { label: LANGUAGES[0].languageCode };
    return defaultLanguage.label.toUpperCase();
  });

  useEffect(() => {
    setLanguageTitle(language.toUpperCase());
  }, [language]);

  useEffect(() => {
    if (!getLocalLanguage()) {
      dispatch(handleLanguageChange(LANGUAGES[0].languageCode));
    }
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelMenuItemClick = (menu) => {
    const payload = {
      locale: menu.languageCode
    }
    if (window.bt) {
      window.bt.kill()
    }

    if (getAuthToken()) {
      dispatch(updateLanguageThunk(payload));
    } else {
      dispatch(handleLanguageChange(menu.languageCode));
    }
    handleClose();
  };
  return (
    <Grid className="custom-dropdown">
      <Button
        id="basic-button-2"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: colors.white }}
      >
        <GetIcon languageCode={language.toLowerCase()} />
        <span style={{ marginLeft: "5px" }}> {languageTitle} </span>
        <DownArrowWhiteIcon />
      </Button>
      <Menu
        id="basic-menu-2"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            transform: 'translateX(4%) translateY(-100%)',
          }
        }}
        className={classes.dropdownElementProfile}
      >
        {Array.isArray(LANGUAGES) &&
          LANGUAGES.map((menu) => (
            <MenuItem
              key={menu?.languageCode}
              className={`${language === menu?.languageCode ? "active" : ""}`}
              onClick={() => handelMenuItemClick(menu)}
            >
              <GetIcon languageCode={menu.languageCode} />{" "}
              <span style={{ marginLeft: "5px" }}>
                {menu.languageCode.toUpperCase()}
              </span>
            </MenuItem>
          ))}
      </Menu>
    </Grid>
  );
};
const Footer = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { socialMedialLinksData } = useSelector((state) => state?.footer);
  const { cmsData } = useSelector((state) => state.cms);
  const { language } = useSelector((state) => state.user);

  const renderCMSData = useMemo(() => {
    const result = Object.keys(cmsData).map((key) => {
      return {
        title: cmsData?.[key]?.title?.[language?.toUpperCase()] || "",
        content: cmsData?.[key]?.content?.[language?.toUpperCase()] || "",
        category: cmsData?.[key]?.category,
        slug: cmsData?.[key]?.slug
      };
    });
    const supportData = result?.filter((res) => res?.category === 1);
    const aboutUsData = result?.filter((res) => res?.category === 2);
    const responsibleGamingData = result?.filter((res) => res?.category === 3);

    return { supportData, aboutUsData, responsibleGamingData };
  }, [cmsData]);

  const cmsOthersData = useMemo(() => {
    return [
      {
        title: t("affiliates"),
        path: ROUTE_PATHS?.affiliates,
      },
    ];
  }, []);

  const openCILCertificate = () => {
    const url =
      "https://nam12.safelinks.protection.outlook.com/ap/b-59584e83/?url=https%3A%2F%2Fknoxxit2.sharepoint.com%2F%3Ab%3A%2Fs%2FCIL-Validations%2FEcDPj1RFJMROgp1AizyJ-UIBTUtv0Il2daE6gPrJ3dAVPw%3Fe%3D623tkZ&data=05%7C02%7Cjeroen.vanwestendorp%40hbmgroup.com%7Cdfcca95934374475f4dc08dc371a7077%7Cab6f126377d444e78de12c3ddcaa94ef%7C0%7C0%7C638445833881705197%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=lwYp14pobpLLVvo%2BE1rCc9pPtQRHEL36isdXAVq%2BvwE%3D&reserved=0";
    window.open(url, "_blank");
  };
  const openGA = () => {
    const url = "https://www.gamblersanonymous.org/ga/";
    window.open(url, "_blank");
  };
  const openGC = () => {
    const url = "https://www.gamcare.org.uk";
    window.open(url, "_blank");
  };

  useEffect(() => {
    dispatch(fetchSocialLinksThunk({}));
  }, []);

  const getCategory = useMemo(() => {
    return headerLinks(t);
  }, [headerLinks]);

  const paymentMethods = [
    {
      name: "payment_1",
      imageUrl: payment_1,
    },
    {
      name: "payment_1",
      imageUrl: payment_2,
    },
    {
      name: "payment_1",
      imageUrl: payment_pix,
    },

    {
      name: "payment_1",
      imageUrl: payment_5,
    },
    {
      name: "payment_1",
      imageUrl: payment_6,
    },
    {
      name: "payment_1",
      imageUrl: payment_7,
    },
  ];
  useEffect(() => {
    dispatch(fetchAllCmsData({}));
  }, []);
  return (
    <Box as={"footer"} className={classes.FooterWrapper}>
      <Grid className={classes.mainWrap}>
        <Grid className={classes.paymentOption}>
          <Typography>{t("paymentMethods")}</Typography>
          <Grid className={classes.popularLogo}>
            {paymentMethods?.map((payment) => (
              <img src={payment?.imageUrl} alt={payment?.name} />
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} lg={3}>
            <FooterLinks
              renderLinksData={getCategory}
              className={classes.footerQuickLinks}
              title={t("category")}
            />
          </Grid>
          {renderCMSData?.supportData?.length > 0 && (
            <Grid item xs={6} lg={3}>
              <Grid className={classes.footerQuickLinks}>
                <Typography variant="h4">{t("support")}</Typography>
                <a
                  href="https://ajuda.onlybets.tv"
                  target="_blank"
                  title={t("supportCenter")}

                >
                  {t("supportCenter")}
                </a>
                <a
                  href="javascript:window.Tawk_API.maximize()"
                  title={t("liveSupport")}

                >
                  {t("liveSupport")}
                </a>

                {renderCMSData?.supportData?.map((renderCMSData) => {
                  return (
                    (t(renderCMSData?.title) ? <NavLink
                      to={ROUTE_PATHS.support}
                      state={{
                        title: renderCMSData?.title || "",
                        content: renderCMSData?.content || "",
                      }}
                    >
                      {t(renderCMSData?.title)}
                    </NavLink> : '')
                  );
                })}
              </Grid>
            </Grid>
          )}
          {socialMedialLinksData?.socialLinks?.length > 0 && (
            <Grid item xs={6} lg={3}>
              <FooterLinks
                renderLinksData={socialMedialLinksData?.socialLinks}
                className={classes.footerQuickLinks}
                title={t("socialMedia")}
                isTargetBlank
              />
            </Grid>
          )}
          {cmsOthersData?.length > 0 && (
            <Grid
              className={classes.footerQuickLinks}
              mt={renderCMSData?.responsibleGamingData?.length > 0 ? 1 : 0}
            >
              <Typography variant="h4">{t("others")}</Typography>

              {cmsOthersData?.map((renderCMSData) => {
                return (
                  <NavLink
                    to={renderCMSData?.path}
                    state={{
                      title: renderCMSData?.title || "",
                      content: renderCMSData?.content || "",
                    }}
                  >
                    {t(renderCMSData?.title)}
                  </NavLink>
                );
              })}
            </Grid>
          )}
        </Grid>


        <Grid className={classes.popularOption}>
          <Typography>{t("popularProviders")}</Typography>
          <Grid className={classes.popularLogo}>
            <img src={provider_1} alt="provider_1" />
            <img src={provider_2} alt="provider_2" />
            <img src={provider_3} alt="provider_3" />
            <img src={provider_4} alt="provider_4" />
            <img src={provider_5} alt="provider_5" />
            <img src={provider_6} alt="provider_6" />
          </Grid>
        </Grid>

        <Grid className={classes.footerLogoSection}>
          <Link href="javascript:void(0);">
            <BrandLogoIcon />
          </Link>
          <Grid className={classes.footerInstruction}>
            <GambleAware
              onClick={openGA}
              style={{ borderRadius: "10px", cursor: "pointer", width: '9rem' }}
            />
            <img
              src={licence_logo}
              alt="licence logo"
              height="50px"
              width="50px"
              style={{ borderRadius: "10px", cursor: "pointer" }}
              onClick={openCILCertificate}
            />
            <NavLink
              to={ROUTE_PATHS.support}
              state={{
                title: renderCMSData?.supportData?.filter(e => e.slug == 'responsible-gambling')[0]?.title || "",
                content: renderCMSData?.supportData?.filter(e => e.slug == 'responsible-gambling')[0]?.content || "",
              }}
            >
              <AdulltIcon />
            </NavLink>
            <GamCare
              onClick={openGC}
              style={{ borderRadius: "10px", cursor: "pointer", marginLeft: 0, width: '7rem' }}
            />
          </Grid>
          <Grid className={classes.languageWrap}>
            <CustomeDropDown />
          </Grid>
        </Grid>
        <Grid className={classes.footerCopyright}>
          <Typography>© {t("copyrightMessage")}</Typography>
          <Typography>{t("wwwOnlybets")}</Typography>
        </Grid>
      </Grid>
    </Box>

  );
};

export default Footer;
