import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformationThunk } from 'redux-thunk/thunk/user/user.thunk'
import { getAuthToken } from 'helpers/cookie.helpers'

const useUserDetails = () => {
  const dispatch = useDispatch()
  const { userInformationData } = useSelector((state) => state.user)

  useEffect(() => {
    if (getAuthToken()) {
      dispatch(fetchUserInformationThunk())
    }
  }, [])

  return {
    userInformationData
  }
}

export default useUserDetails
