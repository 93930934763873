import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstanceService from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

/** Return a affiliate list
 * @typedef { {affiliates: {count: number, rows: Affiliate[]}, message: number} } AffiliateListResponse
 * @typedef { {limit?: number, pageNo?: number } } AffiliateListPayload
 * @type { (payload?: AffiliateListPayload) => Promise<AffiliateListResponse> }
 */
export const fetchInfluencerListService = (payload = {}) => {
  const instance = axiosInstanceService(METHOD_TYPES.get, `/affiliate/all?${new URLSearchParams(payload).toString()}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
  return instance
}

/** Return a affiliate playing now list
 * @typedef { {affiliates: [], message: number} } PlayingNowListResponse
 * @typedef { {limit?: number, pageNo?: number, isLive?: boolean } } PlayingNowListPayload
 * @type { (payload?: PlayingNowListPayload) => Promise<PlayingNowListResponse> }
 */
export const fetchPlayingNowListService = (payload = {}) => {
  const instance = axiosInstanceService(METHOD_TYPES.get, `/affiliate/playing-now?${new URLSearchParams(payload).toString()}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
  return instance
}

/**
 * @typedef {{
 *  affiliateId: number
 *  username: string
 *  firstName: string
 *  lastName: string
 *  streamerId: string
 * }} Affiliate
 */
